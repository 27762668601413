import type { FormikHelpers } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import type Tariff from 'src/lots/models/Tariff';
import type TariffFormValues from 'src/lots/models/TariffFormValues';
import useTerminalsService from 'src/lots/services/useTerminalService';
import ExitButton from 'src/shared/components/Buttons/ExitButton';
import Form from 'src/shared/components/Form';
import { useGlobalFailureModal } from 'src/shared/components/Modals/GlobalFailureModal';
import { useGlobalSuccessModal } from 'src/shared/components/Modals/GlobalSuccessModal';
import Spinner from 'src/shared/components/Spinner';
import useIsMounted from 'src/shared/hooks/useIsMounted';
import { isNotString } from 'src/shared/utils/checks';
import styled from 'styled-components';
import * as yup from 'yup';
import TariffEditForm from '../TariffEditForm/TariffEditForm';

export interface TariffDetailsModalProps {
  tariff: Tariff | undefined;
  closeParentModal: () => void;
  onTariffUpdate: (values: TariffFormValues) => void;
}

export const defaultTariffFormValues: TariffFormValues = {
  name: '',
  price: undefined,
  startsAt: undefined,
  endsAt: undefined,
  entryFreePeriod: undefined,
  gracePeriod: undefined,
  gratisTime: undefined,
  daysOfWeek: [],
  overstay: false,
};

export const tariffValidationSchema = yup.object().shape({
  name: yup.string().required('Name is a required field'),
  price: yup.number().required('Price is a required field'),
  startsAt: yup
    .string()
    .matches(/^([01]\d|2[0-3]):([0-5]\d)$/, 'Invalid time format (HH:MM)')
    .required('From time is required'),
  endsAt: yup
    .string()
    .matches(/^([01]\d|2[0-3]):([0-5]\d)$/, 'Invalid time format (HH:MM)')
    .required('To time is required')
    .test(
      'is-after-fromTime',
      'To time must be after From time',
      function (endsAt) {
        const { startsAt } = this.parent;
        if (!startsAt || !endsAt) return true;
        return startsAt < endsAt;
      }
    ),
  daysOfWeek: yup
    .array()
    .min(1, 'Select at least one day')
    .required('This field is required'),
});

export default function TariffDetailsModal(props: TariffDetailsModalProps) {
  const { closeParentModal, tariff, onTariffUpdate } = props;
  const isMounted = useIsMounted();
  const [initialValues, setInitialValues] = useState<TariffFormValues>(
    defaultTariffFormValues
  );
  const [isInProgress, setIsInProgress] = useState(false);
  const [messageSuccess, setMessageSuccess] = useState('');
  const [shouldRefreshPage, setShouldRefreshPage] = useState(false);
  const { openGlobalSuccessModal } = useGlobalSuccessModal({
    closeParentModal,
    message: messageSuccess,
    refreshPage: shouldRefreshPage,
  });
  const { updateTariff } = useTerminalsService();
  const { openGlobalFailureModal, setMessage } = useGlobalFailureModal({});

  useEffect(() => {
    if (tariff) {
      setInitialValues(tariff);
    }
  }, [tariff]);

  const onTariffFormSubmit = useCallback(
    async (
      values: TariffFormValues,
      { setErrors, resetForm }: FormikHelpers<TariffFormValues>
    ) => {
      try {
        if (!tariff?.id) {
          return;
        }
        setIsInProgress(true);
        if (values.name) onTariffUpdate(values);
        await updateTariff(parseInt(tariff.id), values);

        if (isMounted()) {
          resetForm({});
          setMessageSuccess('Tariff updated successfully!');
          openGlobalSuccessModal();
          setIsInProgress(false);
          setShouldRefreshPage(false);
        }
      } catch (error: any) {
        if (isMounted()) {
          setIsInProgress(false);

          if (isNotString(error) && error.code === undefined) {
            setErrors(error);
            return;
          }
          setMessage(error);
          openGlobalFailureModal();
        }
      }
    },
    [
      tariff?.id,
      onTariffUpdate,
      updateTariff,
      isMounted,
      openGlobalSuccessModal,
      setMessage,
      openGlobalFailureModal,
    ]
  );

  return (
    <>
      {isInProgress && (
        <StyledSpinner>
          <Spinner primary small />
          <StyledSpan>Processing...</StyledSpan>
        </StyledSpinner>
      )}
      <ExitButton onClick={closeParentModal} />
      <Form
        name='tariff'
        initialValues={initialValues}
        validationSchema={tariffValidationSchema}
        onSubmit={onTariffFormSubmit}
      >
        <TariffEditForm tariff={tariff} closeParentModal={closeParentModal} />
      </Form>
    </>
  );
}

const StyledSpinner = styled.div`
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 10000;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  border-radius: 10px;
`;

const StyledSpan = styled.span`
  margin-left: 10px;
`;
