import type { FormikHelpers } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import useLprCamerasService from 'src/anpr/services/useLprCamerasService';

import ZoneAddModal from 'src/lots/components/ZoneAddModal';
import Zones from 'src/lots/components/Zones';
import type Zone from 'src/lots/models/Zone';
import type ZoneData from 'src/lots/models/ZoneData';
import type ZoneDropdown from 'src/lots/models/ZoneDropdown';
import type ZoneFormValues from 'src/lots/models/ZoneFormValues';
import DeleteModal from 'src/products/components/DeleteModal';
import GatewayStatus from 'src/products/constants/GatewayStatus';
import OrderByDirections from 'src/products/constants/OrderByDirection';
import useGatewayService from 'src/products/services/useGatewayService';
import useProductService from 'src/products/services/useProductService';
import useAccountUserService from 'src/settings/services/useAccountUserService';
import GlobalModal from 'src/shared/components/Modals/GlobalModal';
import { useGlobalModal } from 'src/shared/hooks/useGlobalModal';
import useCurrencyService from 'src/shared/services/useCurrencyService';
import { isNotString } from 'src/shared/utils/checks';
import * as yup from 'yup';
import Form from '../../shared/components/Form';
import { useGlobalFailureModal } from '../../shared/components/Modals/GlobalFailureModal';
import { useGlobalSuccessModal } from '../../shared/components/Modals/GlobalSuccessModal';
import useIsMounted from '../../shared/hooks/useIsMounted';
import type Option from '../../shared/models/Option';
import useZoneService from '../services/useZoneService';

const validationSchema = yup.object({
  zoneInfo: yup.array().of(
    yup.object({
      name: yup
        .string()
        .required('Name is a required field')
        .min(3, 'Name should be at least 3 characters long'),
    })
  ),
});

interface ZoneListProps {
  lotId: number | undefined;
}

const defaultZoneValues: ZoneDropdown = {
  zone: null,
};

export default function ZoneList(props: ZoneListProps) {
  const { lotId } = props;
  const [data, setData] = useState<ZoneData[]>([]);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [messageSuccess, setMessageSuccess] = useState('');
  const [newZoneId, setNewZoneId] = useState<Option | undefined>(undefined);
  const [activeZone, setActiveZone] = useState<
    { id: number; name: string } | undefined
  >();
  const { findAllZones, createZone, removeZone, updateZone } = useZoneService();
  const { listAllLprCameras } = useLprCamerasService();
  const { findAllGateways } = useGatewayService();
  const { findAllProducts } = useProductService();
  // const { findAllTariffs } = useTariffService();
  const isMounted = useIsMounted();
  const [currencies, setCurrencies] = useState<Record<number, string>>({});
  const [invoiceCurrency, setInvoiceCurrency] = useState<
    { id: number | undefined; symbol: string | undefined } | undefined
  >(undefined);
  const [accountId, setAccountId] = useState<number | undefined>(undefined);
  const { findAllCurrencies } = useCurrencyService();
  const { findProfileAccountUser } = useAccountUserService();
  const [initialZoneValues, setInitialZoneValues] =
    useState<ZoneDropdown>(defaultZoneValues);

  const [initialValues, setInitialValues] = useState<{
    zoneInfo: ZoneFormValues[];
  }>({ zoneInfo: [] });

  const [openDeleteZoneModal, closeDeleteZoneModal] = useGlobalModal(() => (
    <GlobalModal isOpen>
      <Form
        name='DeleteZone'
        initialValues={{ name: '' }}
        validationSchema={validationSchema}
        onSubmit={onRemove}
      >
        <DeleteModal
          closeParentModal={closeDeleteZoneModal}
          type='zone'
          name={activeZone?.name}
        />
      </Form>
    </GlobalModal>
  ));

  const [openAddZoneModal, closeAddZoneModal] = useGlobalModal(() => (
    <GlobalModal isOpen>
      <Form
        name='AddZone'
        initialValues={{ name: '' }}
        validationSchema={validationSchema}
        onSubmit={onAdd}
      >
        <ZoneAddModal closeParentModal={closeAddZoneModal} />
      </Form>
    </GlobalModal>
  ));

  const { openGlobalSuccessModal: deleteZoneSuccessModal } =
    useGlobalSuccessModal({
      message: messageSuccess,
      closeParentModal: closeDeleteZoneModal,
    });

  const { openGlobalSuccessModal: addZoneSuccessModal } = useGlobalSuccessModal(
    {
      message: messageSuccess,
      closeParentModal: closeAddZoneModal,
    }
  );

  const { openGlobalSuccessModal: updateZoneSuccessModal } =
    useGlobalSuccessModal({
      message: messageSuccess,
    });

  const { openGlobalFailureModal, setMessage } = useGlobalFailureModal({});

  useEffect(() => {
    const getData = async () => {
      try {
        if (!lotId) {
          return;
        }

        if (isMounted()) {
          setIsDataLoading(true);
        }

        const zones = findAllZones({ lotId });
        const gateways = findAllGateways({ lotId });
        const cameras = listAllLprCameras({ lotId });
        const products = findAllProducts({
          lotId,
          orderByDirection: OrderByDirections.ASC,
        });
        // const tariffs = findAllTariffs();

        const response = await Promise.all([
          zones,
          gateways,
          cameras,
          products,
          // tariffs,
        ]);
        const [
          { data: zoneData },
          { data: gatewayData },
          { data: cameraData },
          { data: productData },
        ] = response;

        if (isMounted()) {
          const allOnlineGateways = gatewayData.filter(
            (gateway) => gateway.status === GatewayStatus.ONLINE
          );
          setInitialValues({
            zoneInfo: zoneData.map((d) => ({
              id: d.id,
              name: d.name,
              isPrivate: d.isPrivate,
            })),
          });
          if (zoneData.length > 0) {
            const zoneDetails = {
              zone: {
                key: zoneData[0].id,
                label: zoneData[0].name,
              },
            };

            setInitialZoneValues(zoneDetails);
          }

          const mergedData = zoneData.map((zone) => ({
            ...zone,
            products: productData.filter(
              (product) => product.zoneId === zone.id
            ),
            cameras: cameraData.filter((camera) => camera.zoneId === zone.id),
            isAnyGatewayOnline: allOnlineGateways.length > 0,
          }));
          setData(mergedData);
          setIsDataLoading(false);
        }
      } catch (error) {
        if (isMounted()) {
          setIsDataLoading(false);
        }
        throw error;
      }
    };
    getData();
  }, [
    findAllGateways,
    findAllProducts,
    findAllZones,
    isMounted,
    listAllLprCameras,
    lotId,
    // findAllTariffs,
  ]);

  useEffect(() => {
    const getData = async () => {
      try {
        if (isMounted()) {
          setIsDataLoading(true);
        }
        const currencies = findAllCurrencies();
        const profile = findProfileAccountUser();

        const response = await Promise.all([currencies, profile]);
        const [currencyData, profileData] = response;

        if (isMounted()) {
          const reducedData = currencyData.reduce(
            (acc, item) => ({
              ...acc,
              [item.id]: item.symbol,
            }),
            {}
          );
          setCurrencies(reducedData);
          setInvoiceCurrency({
            id: profileData.account?.invoiceCurrency?.id,
            symbol: profileData.account?.invoiceCurrency?.symbol,
          });
          setAccountId(profileData.account?.id);
          setIsDataLoading(false);
        }
      } catch (error) {
        if (isMounted()) {
          setIsDataLoading(false);
        }
        throw error;
      }
    };
    getData();
  }, [isMounted, findAllCurrencies, findProfileAccountUser]);

  const findCurrencySymbol = useCallback(
    (id: number) => currencies[id],
    [currencies]
  );

  const getChangedValues = useCallback(
    (values: ZoneFormValues[]) => {
      const newValues = values.map((zone) => {
        const result = data.find((d) => d.id === zone.id);
        return {
          ...zone,
          name: result?.name !== zone.name ? zone.name : undefined,
          isPrivate:
            result?.isPrivate !== zone.isPrivate ? zone.isPrivate : undefined,
        };
      });

      return newValues.filter(
        (value) => !(value.name === undefined && value.isPrivate === undefined)
      );
    },
    [data]
  );

  const onSubmit = useCallback(
    async (values: { zoneInfo: ZoneFormValues[] }) => {
      let failed: {
        [key: number]: {
          failedStatus: boolean;
          name: string | undefined;
        };
      } = {};

      const { zoneInfo } = values;

      const valuesToUpdate = getChangedValues(zoneInfo);

      for (const value of valuesToUpdate) {
        try {
          if (!value.id) return;
          await updateZone(value.id, value);
        } catch {
          if (!value.id) return;
          failed = {
            ...failed,
            [value.id]: { failedStatus: true, name: value.name },
          };
        }
      }

      if (isMounted()) {
        setInitialZoneValues({
          zone: null,
        });
        setData((oldData) =>
          oldData.map((zone) => {
            const result = valuesToUpdate.find(
              (value) => value.id === zone.id && !failed[value.id]
            );
            if (result) {
              return {
                ...zone,
                name: result.name ? result.name : zone.name,
                isPrivate: result.isPrivate ? result.isPrivate : zone.isPrivate,
              };
            }
            return zone;
          })
        );
        if (Object.keys(failed).length > 0) {
          const failedZones = Object.values(failed).map((item) => item.name);
          setMessage({
            code: 'Undefined',
            message: `Sorry for the inconvenience! Something went wrong at zones with names: ${failedZones}. \n Please try again.`,
          });
          openGlobalFailureModal();
          return;
        }
        setMessageSuccess('Zones updated successfully!');
        updateZoneSuccessModal();
      }
    },
    [
      isMounted,
      updateZone,
      getChangedValues,
      openGlobalFailureModal,
      setMessage,
      updateZoneSuccessModal,
    ]
  );

  const onAdd = useCallback(
    async (
      values: { name: string },
      { setErrors }: FormikHelpers<{ name: string }>
    ) => {
      try {
        if (!lotId) {
          return;
        }

        const { name } = values;
        const res = await createZone({ name }, lotId);

        if (isMounted()) {
          setData((oldData) => [
            ...oldData,
            {
              ...res,
              cameras: [],
              products: [],
              isAnyGatewayOnline: false,
              tariffs: [],
            },
          ]);
          setNewZoneId({ key: res.id, label: res.name });
          setInitialValues((oldData) => ({
            zoneInfo: [...oldData.zoneInfo, res],
          }));
          setInitialZoneValues({
            zone: {
              key: res.id,
              label: res.name,
            },
          });
          setMessageSuccess('Zone added successfully!');
          addZoneSuccessModal();
        }
      } catch (error: any) {
        if (isMounted()) {
          if (isNotString(error) && error.code === undefined) {
            setErrors(error);
            return;
          }
          setMessage(error);
          openGlobalFailureModal();
        }
      }
    },
    [
      lotId,
      createZone,
      isMounted,
      addZoneSuccessModal,
      openGlobalFailureModal,
      setMessage,
    ]
  );

  const onRemove = useCallback(
    async (values: { name: string }) => {
      try {
        if (activeZone?.id) {
          const { name } = values;
          if (activeZone.name !== name && isMounted()) {
            setMessage({
              code: 'Undefined',
              message: "Name doesn't match. Please try again.",
            });
            openGlobalFailureModal();
          } else {
            await removeZone(activeZone.id);

            if (isMounted()) {
              setData((oldData) =>
                oldData.filter((data) => data.id !== activeZone.id)
              );
              setInitialValues((oldData) => ({
                zoneInfo: oldData.zoneInfo.filter(
                  (data) => data.id !== activeZone.id
                ),
              }));
              setActiveZone(undefined);
              setMessageSuccess('Zone deleted successfully!');
              deleteZoneSuccessModal();
            }
          }
        }
      } catch (error: any) {
        if (isMounted()) {
          setMessage(error);
          openGlobalFailureModal();
        }
      }
    },
    [
      removeZone,
      isMounted,
      activeZone,
      deleteZoneSuccessModal,
      openGlobalFailureModal,
      setMessage,
    ]
  );

  const openRemoveModal = useCallback(
    (data: Zone) => {
      setActiveZone({ id: data.id, name: data.name });
      openDeleteZoneModal();
    },
    [openDeleteZoneModal]
  );

  const onCameraNameChange = useCallback(
    (name: string, id: number, zoneId?: number) => {
      if (name === '' || id === 0 || !zoneId) return;
      setData((oldData) => {
        const zoneIndex = oldData.findIndex((data) => data.id === zoneId);

        if (zoneIndex === -1) return oldData;

        const index = oldData[zoneIndex].cameras.findIndex(
          (data) => data.id === id
        );

        oldData[zoneIndex].cameras[index].name = name;
        return [...oldData];
      });
    },
    []
  );

  const onProductNameChange = useCallback(
    (name: string, id: number, zoneId?: number) => {
      if (name === '' || id === 0 || !zoneId) return;
      setData((oldData) => {
        const zoneIndex = oldData.findIndex((data) => data.id === zoneId);

        if (zoneIndex === -1) return oldData;

        const index = oldData[zoneIndex].products.findIndex(
          (data) => data.id === id
        );

        oldData[zoneIndex].products[index].name = name;
        return [...oldData];
      });
    },
    []
  );

  const onTariffChange = useCallback(() => {
    // (tariffs: TariffId[] | Tariff, zoneId: number) => {
    //   if (!tariffs || !zoneId) return;
    //   const isTariffFindAllDetails = (
    //     tariffs: TariffId[] | Tariff
    //   ): tariffs is Tariff => (tariffs as Tariff).name !== undefined;
    //   setData((oldData) => {
    //     const zoneIndex = oldData.findIndex((data) => data.id === zoneId);
    //     if (zoneIndex === -1) return oldData;
    //     if (isTariffFindAllDetails(tariffs)) {
    //       oldData[zoneIndex].tariffs = [...oldData[zoneIndex].tariffs, tariffs];
    //     } else {
    //       oldData[zoneIndex].tariffs = tariffs.map((tariff) => tariff.tariff);
    //     }
    //     return [...oldData];
    //   });
  }, []);

  return (
    <Form
      name='Zone'
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      isLoading={isDataLoading}
    >
      <Zones
        isLoading={isDataLoading}
        openRemoveModal={openRemoveModal}
        data={data}
        initialValues={initialZoneValues}
        onAdd={openAddZoneModal}
        newZoneId={newZoneId}
        onCameraNameChange={onCameraNameChange}
        onProductNameChange={onProductNameChange}
        onTariffChange={onTariffChange}
        findCurrencySymbol={findCurrencySymbol}
        invoiceCurrency={invoiceCurrency}
        accountId={accountId}
      />
    </Form>
  );
}
