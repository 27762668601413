import type Session from '../models/Session';
import type SessionResponse from '../models/SessionResponse';
import ToPayment from './toPayment';

function toSession(props: SessionResponse): Session {
  const {
    amount_paid,
    entry_terminal,
    entry_type,
    possibly_exited,
    session_id,
    started_at,
    ended_at,
    payments,
  } = props;
  return {
    amountPaid: amount_paid,
    entryTerminal: entry_terminal,
    entryType: entry_type,
    possiblyExited: possibly_exited,
    sessionId: session_id,
    startedAt: new Date(started_at),
    endedAt: ended_at === undefined ? undefined : new Date(ended_at),
    payments: payments.map(ToPayment),
  };
}
export default toSession;
