import React from 'react';
import Table from 'src/shared/components/Table';
import ActionIcon from 'src/shared/constants/ActionIcon';

interface TerminalsInternalTableRowProps {
  id: number;
  deviceId: string;
  name: string;
  onRemoveItem: (id: number) => void;
  onOpenModal: () => void;
}

export default function TerminalsInternalTableRow({
  id,
  deviceId,
  name,
  onOpenModal,
  onRemoveItem,
}: TerminalsInternalTableRowProps) {
  return (
    <Table.Row key={id}>
      <Table.Cell>{id}</Table.Cell>
      <Table.Cell>{deviceId}</Table.Cell>
      <Table.Cell>{name}</Table.Cell>
      <Table.Cell
        center
        action={() => {
          onRemoveItem(id);
        }}
        primary
        data-testid={'remove-button'}
      >
        <div data-testid={'remove-button'}>
          <Table.Icon icon={ActionIcon.DELETE} />
        </div>
      </Table.Cell>
      <Table.Cell center action={onOpenModal} primary>
        <div data-testid={'edit-button'}>
          <Table.Icon icon={ActionIcon.EDIT} />
        </div>
      </Table.Cell>
    </Table.Row>
  );
}
