import type { FormikHelpers } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Form from 'src/shared/components/Form';
import { useGlobalFailureModal } from 'src/shared/components/Modals/GlobalFailureModal';
import { useGlobalSuccessModal } from 'src/shared/components/Modals/GlobalSuccessModal';
import SessionStorageKey from 'src/shared/constants/SessionStorageKey';
import * as yup from 'yup';
import useAccountService from '../../home/services/useAccountService';
import AppRole from '../../shared/constants/AppRole';
import PhoneValidation from '../../shared/constants/PhoneValidation';
import useIsMounted from '../../shared/hooks/useIsMounted';
import useUserHasRole from '../../shared/hooks/useUserHasRole';
import { isNotString } from '../../shared/utils/checks';
import AccountInfoForm from '../components/AccountInfoForm';
import toAccountInfoFormValuesId from '../mappers/toAccountInfoByIdFormValues';
import toAccountInfoFormValues from '../mappers/toAccountInfoFormValues';
import type AccountInfoFormValues from '../models/AccountInfoFormValues';
import useAccountUserService from '../services/useAccountUserService';

export const accountInfoDefaultValues: AccountInfoFormValues = {
  name: '',
  accountRole: null,
  contactName: '',
  contactEmail: '',
  contactPhone: '',
  organisationId: '',
  customPercentage: null,
  myPosSecret: '',
  myPosClientId: '',
};

export const accountInfoValidationSchema = yup.object({
  name: yup.string().required('Name is a required field'),
  accountRole: yup
    .object()
    .nullable()
    .required('Account Type is a required field'),
  contactName: yup.string().notRequired(),
  organisationId: yup
    .string()
    .notRequired()
    .when('customPercentage', {
      is: (val: number) => val !== null && val !== undefined,
      then: (schema) =>
        schema.required('Name is required when percentage is entered'),
    }),
  customPercentage: yup
    .number()
    .nullable()
    .typeError('Value must be a number')
    .min(0.001, 'Value must be at least 0.001')
    .lessThan(100, 'Value must be less than 100'),
  contactEmail: yup.string().email().notRequired(),
  contactPhone: yup
    .string()
    .notRequired()
    .trim()
    .matches(PhoneValidation, 'Enter a valid contact phone number'),
  myPosClientId: yup.string().notRequired().nullable(),
  myPosSecret: yup.string().notRequired().nullable(),
});

interface Props {
  accountId: number;
}

export default function AccountInfoEdit(props: Props) {
  const { accountId } = props;
  const [initialValues, setInitialValues] = useState<AccountInfoFormValues>(
    accountInfoDefaultValues
  );
  const [isLoading, setIsLoading] = useState(false);
  const isMounted = useIsMounted();
  const { findProfileAccountUser } = useAccountUserService();
  const { findAccountById, updateAccount } = useAccountService();
  const userHasRole = useUserHasRole();

  const { openGlobalSuccessModal } = useGlobalSuccessModal({
    message: 'Successfuly updated account info!',
  });

  const { openGlobalFailureModal, setMessage } = useGlobalFailureModal({});

  const userIsParklioInternal = useMemo(() => {
    const roles =
      AppRole.PARKLIO_INTERNAL_SUPER_ADMIN | AppRole.PARKLIO_INTERNAL_ADMIN;

    return userHasRole(roles);
  }, [userHasRole]);

  useEffect(() => {
    const apiCall = async () => {
      try {
        if (isMounted()) {
          setIsLoading(true);
        }

        let formValues: AccountInfoFormValues | undefined;

        if (
          userIsParklioInternal &&
          accountId !==
            parseInt(sessionStorage.getItem(SessionStorageKey.ID) || '')
        ) {
          const dataInternal = await findAccountById(accountId);
          formValues = toAccountInfoFormValuesId(dataInternal);
        } else {
          const data = await findProfileAccountUser();
          formValues = toAccountInfoFormValues(data);
        }

        if (isMounted()) {
          setIsLoading(false);
          setInitialValues((values) => formValues || values);
        }
      } catch (error) {
        if (isMounted()) {
          setIsLoading(false);
        }
        throw error;
      }
    };

    apiCall();
  }, [
    findProfileAccountUser,
    findAccountById,
    userIsParklioInternal,
    isMounted,
    setIsLoading,
    accountId,
  ]);

  const getChangedValues = useCallback(
    (values: AccountInfoFormValues) => ({
      name: values.name !== initialValues.name ? values.name : undefined,
      accountRole:
        values.accountRole?.key !== initialValues.accountRole?.key
          ? values.accountRole
          : undefined,
      contactName:
        values.contactName !== initialValues.contactName
          ? values.contactName
          : undefined,
      contactEmail:
        values.contactEmail !== initialValues.contactEmail
          ? values.contactEmail
          : undefined,
      contactPhone:
        values.contactPhone !== initialValues.contactPhone
          ? values.contactPhone === ''
            ? null
            : values.contactPhone
          : undefined,
      myPosSecret:
        values.myPosSecret !== initialValues.myPosSecret
          ? values.myPosSecret === ''
            ? null
            : values.myPosSecret
          : undefined,
      myPosClientId:
        values.myPosClientId !== initialValues.myPosClientId
          ? values.myPosClientId === ''
            ? null
            : values.myPosClientId
          : undefined,
      customPercentage:
        values.customPercentage !== initialValues.customPercentage
          ? values.customPercentage?.toString() === ''
            ? null
            : values.customPercentage
          : undefined,
      organisationId:
        values.organisationId !== initialValues.organisationId
          ? values.organisationId === ''
            ? null
            : values.organisationId
          : undefined,
    }),
    [initialValues]
  );

  const onSubmit = useCallback(
    async (
      values: AccountInfoFormValues,
      { setErrors }: FormikHelpers<AccountInfoFormValues>
    ) => {
      try {
        const valuesToUpdate = getChangedValues(values);
        if (isMounted()) {
          setIsLoading(true);
        }
        await updateAccount(valuesToUpdate, accountId);

        if (isMounted()) {
          setInitialValues(values);
          setIsLoading(false);
          openGlobalSuccessModal();
        }
      } catch (error: any) {
        if (isMounted()) {
          setIsLoading(false);
          if (isNotString(error) && error.code === undefined) {
            setErrors(error);
            return;
          }
          setMessage(error);
          openGlobalFailureModal();
        }
      }
    },
    [
      updateAccount,
      getChangedValues,
      isMounted,
      openGlobalSuccessModal,
      openGlobalFailureModal,
      accountId,
      setMessage,
    ]
  );

  return (
    <Form
      name='accountInfo'
      initialValues={initialValues}
      validationSchema={accountInfoValidationSchema}
      isLoading={isLoading}
      onSubmit={onSubmit}
    >
      <AccountInfoForm />
    </Form>
  );
}
