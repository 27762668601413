import { CategoryScale, Chart, LinearScale, BarElement } from 'chart.js';
import React, { useState, useMemo, useCallback } from 'react';
import { Bar } from 'react-chartjs-2';
import Card from 'src/shared/components/Card';
import Color from 'src/shared/constants/Color';
import Days from 'src/shared/constants/Days';
import styled, { css } from 'styled-components';

Chart.register(CategoryScale);
Chart.register(LinearScale);
Chart.register(BarElement);

const MONDAY = [
  3.23, 2.12, 0.4, 0, 0, 2.23, 15.8, 35.63, 52.3, 60, 44.35, 40.49, 42.74,
  37.01, 41.63, 57.4, 50.02, 25.2, 6.63, 7.04, 6.74, 10, 3.02, 3.12,
];
const TUESDAY = [
  2.87, 2.12, 3.85, 2.74, 2.1, 4.36, 14.69, 28.74, 45.03, 55, 57.7, 43.63,
  32.58, 37.9, 35.2, 50.1, 48.02, 20.74, 10.69, 5.63, 4.41, 6.74, 2.7, 2.03,
];
const WEDNESDAY = [
  1.12, 0, 0, 0, 0.6, 1.85, 10.321, 20.48, 36.74, 52.3, 45.96, 40.21, 34.7,
  30.66, 45.35, 57.4, 45, 15.4, 7.7, 2.2, 0, 1.41, 1.52, 3.2,
];
const THURSDAY = [
  3.74, 2.6, 1.01, 0, 0, 16.65, 30.3, 50.7, 58.66, 50.02, 41.4, 42.66, 35.7,
  50.88, 55.74, 47.56, 14.32, 7.12, 3.4, 1.06, 1.6, 0, 0.2, 1.1,
];
const FRIDAY = [
  0.2, 0, 0, 0, 0, 0, 17.4, 45.56, 47.41, 55.1, 30.78, 25.85, 20.03, 35.4,
  49.36, 55, 40.52, 15.1, 4.45, 3.3, 4.1, 5.02, 4.9, 6.24,
];
const SATURDAY = [
  5.2, 5.25, 4.14, 2.02, 1.01, 1.02, 0, 0.23, 10.21, 12.74, 7.56, 10.85, 8.02,
  6.45, 8.54, 4.02, 4.45, 0.8, 2.2, 3.14, 1.02, 2.04, 3.8, 4.65,
];
const SUNDAY = [
  3.1, 3.23, 1.7, 0, 0, 0, 0, 0.6, 2.23, 3.01, 2.84, 1.02, 2.54, 0.89, 1.23,
  3.65, 5.46, 4.26, 6.98, 3.47, 0.85, 0.3, 1.21, 0,
];

export default function AverageOccupancyGraph() {
  const [avgOccupancyData, setAvgOccupancyData] = useState<number[]>(MONDAY);
  const [day, setDay] = useState(Days.MONDAY);

  const generateTime = useMemo(() => {
    const times = [];
    for (let i = 0; i < 24; ++i) {
      times.push(`${i}:00`);
    }
    return times;
  }, []);

  const getAvgOccupancy = useCallback((weekday: number) => {
    setDay(weekday);
    switch (weekday) {
      case Days.MONDAY:
        setAvgOccupancyData(MONDAY);
        break;
      case Days.TUESDAY:
        setAvgOccupancyData(TUESDAY);
        break;
      case Days.WEDNESDAY:
        setAvgOccupancyData(WEDNESDAY);
        break;
      case Days.THURSDAY:
        setAvgOccupancyData(THURSDAY);
        break;
      case Days.FRIDAY:
        setAvgOccupancyData(FRIDAY);
        break;
      case Days.SATURDAY:
        setAvgOccupancyData(SATURDAY);
        break;
      case Days.SUNDAY:
        setAvgOccupancyData(SUNDAY);
        break;
      default:
        setAvgOccupancyData([]);
        break;
    }
  }, []);

  const avgOccupancyInfo = {
    labels: generateTime,
    datasets: [
      {
        data: avgOccupancyData,
        backgroundColor: '#0591CE',
        label: 'Average occupancy during the day',
      },
    ],
  };

  const avgOccupancyOptions = {
    responsive: true,
    legend: {
      display: true,
    },
  };

  return (
    <Card>
      <StyledDayContainer>
        <StyledDay
          onClick={() => getAvgOccupancy(Days.MONDAY)}
          active={day === Days.MONDAY}
        >
          Monday
        </StyledDay>
        <StyledDay
          onClick={() => getAvgOccupancy(Days.TUESDAY)}
          active={day === Days.TUESDAY}
        >
          Tuesday
        </StyledDay>
        <StyledDay
          onClick={() => getAvgOccupancy(Days.WEDNESDAY)}
          active={day === Days.WEDNESDAY}
        >
          Wednesday
        </StyledDay>
        <StyledDay
          onClick={() => getAvgOccupancy(Days.THURSDAY)}
          active={day === Days.THURSDAY}
        >
          Thursday
        </StyledDay>
        <StyledDay
          onClick={() => getAvgOccupancy(Days.FRIDAY)}
          active={day === Days.FRIDAY}
        >
          Friday
        </StyledDay>
        <StyledDay
          onClick={() => getAvgOccupancy(Days.SATURDAY)}
          active={day === Days.SATURDAY}
        >
          Saturday
        </StyledDay>
        <StyledDay
          onClick={() => getAvgOccupancy(Days.SUNDAY)}
          active={day === Days.SUNDAY}
        >
          Sunday
        </StyledDay>
      </StyledDayContainer>
      <Bar data={avgOccupancyInfo} options={avgOccupancyOptions} />
    </Card>
  );
}

const StyledDayContainer = styled.div`
  width: 100%;
  height: 75px;
  background-color: ${Color.BACKGROUND_LIGHTER};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  overflow: auto;
  margin-bottom: 20px;
`;

const StyledDay = styled.p<{ active: boolean }>`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 0px 20px;
  text-decoration: none;
  color: ${Color.TEXT_DARKER};
  height: 40px;

  ${(props) => {
    const { active } = props;

    if (!active) {
      return css``;
    }

    return css`
      border-radius: 5px;
      background-color: ${Color.BACKGROUND_LIGTH};
      color: ${Color.TEXT_BRAND};
      :hover {
        color: ${Color.PRIMARY_HOVER};
      }
    `;
  }}
`;
