import React, { useCallback, useEffect, useMemo, useState } from 'react';
import TerminalStatus from 'src/lots/constants/TerminalStatus';
import type Tariff from 'src/lots/models/Tariff';
import type TariffFormValues from 'src/lots/models/TariffFormValues';
import type Terminal from 'src/lots/models/Terminal';
import type ZoneModel from 'src/lots/models/Zone';
import type ZoneData from 'src/lots/models/ZoneData';
import useTerminalsService from 'src/lots/services/useTerminalService';
import Button from 'src/shared/components/Button';
import Card from 'src/shared/components/Card';
import Line from 'src/shared/components/Line';
import GlobalModal from 'src/shared/components/Modals/GlobalModal';
import Pagination from 'src/shared/components/Pagination';
import TextField from 'src/shared/components/TextField';
import ToggleSwitchForFormik from 'src/shared/components/ToggleSwitchForFormik';
import AppRole from 'src/shared/constants/AppRole';
import ArrowIcons from 'src/shared/constants/ArrowIcons';
import ButtonSize from 'src/shared/constants/ButtonSize';
import Color from 'src/shared/constants/Color';
import PaginationSize from 'src/shared/constants/DataSize';
import InitialMetaData from 'src/shared/constants/InitialMetaData';
import PaginationItemDisplay from 'src/shared/constants/PaginationItemDisplay';
import { useGlobalModal } from 'src/shared/hooks/useGlobalModal';
import useIsMounted from 'src/shared/hooks/useIsMounted';
import useLicenseContext from 'src/shared/hooks/useLicenseContext';
import useUserHasRole from 'src/shared/hooks/useUserHasRole';
import { FilterRule } from 'src/shared/models/FilterRule';
import type Meta from 'src/shared/models/Meta';
import styled from 'styled-components';
import StyledNavLink from '../StyledNavLink/StyledNavLink';
import TariffDetailsModal from '../TariffDetailsModal/TariffDetailsModal';
import TariffTable from '../TariffTable/TariffTable';
import TerminalList from '../TerminalList/TerminalList';
import ZonesProducts from '../ZoneProducts';

export interface ZoneProps {
  data: ZoneData;
  index: number;
  onClick: () => void;
  toggle: boolean;
  zoneId: number;
  elementId: string | undefined;
  openRemoveModal: (data: ZoneModel) => void;
  onCameraNameChange: (name: string, id: number, zoneId?: number) => void;
  onProductNameChange: (name: string, id: number, zoneId?: number) => void;
  findCurrencySymbol: (id: number) => void;
  invoiceCurrency:
    | { id: number | undefined; symbol: string | undefined }
    | undefined;
  accountId: number | undefined;
}

export default function Zone(props: ZoneProps) {
  const {
    data,
    index,
    onClick,
    toggle,
    zoneId,
    elementId,
    openRemoveModal,
    onCameraNameChange,
    onProductNameChange,
  } = props;
  const { id, cameras } = data;
  const { purchasedLicenses } = useLicenseContext();
  const { findAllTerminals, operateTerminal, getTariffs } =
    useTerminalsService();
  const userHasRole = useUserHasRole();
  const [isDataLoading, setIsDataLoading] = useState(false);

  const [terminalData, setTerminalData] = useState<Terminal[]>([]);
  const [tariffData, setTariffData] = useState<Tariff[]>([]);
  const [meta, setMeta] = useState<Meta>(InitialMetaData);

  const masterTerminalId = useMemo(() => {
    const terminal = terminalData.find((terminal) => terminal.isMaster);
    return terminal?.id;
  }, [terminalData]);

  const onTerminalNameChange = useCallback((name: string, id: number) => {
    if (name === '' || id === 0) return;
    setTerminalData((oldData) => {
      const zoneIndex = oldData.findIndex((data) => data.id === id);
      if (zoneIndex === -1) return oldData;
      oldData[zoneIndex].name = name;
      return [...oldData];
    });
  }, []);

  const onTariffChange = useCallback((tariff: TariffFormValues) => {
    if (!tariff) return;
    setTariffData((oldData) => ({ ...oldData, ...tariff }));
  }, []);

  const isMounted = useIsMounted();

  const userHasCamera = useMemo(() => {
    const numberOfLprCameras = purchasedLicenses.filter(
      (data) => data.lprCameras
    );
    return numberOfLprCameras.length > 0;
  }, [purchasedLicenses]);

  const userIsOperater = useMemo(() => {
    const roles = AppRole.PARKLIO_INTERNAL_OPERATOR | AppRole.PMS_OPERATOR;
    return userHasRole(roles);
  }, [userHasRole]);

  const getTerminalData = useCallback(
    async (page: number) => {
      try {
        if (isMounted()) {
          setIsDataLoading(true);
        }
        const { data, meta } = await findAllTerminals({
          zoneId,
          page,
          size: PaginationSize.STANDARD,
        });
        if (isMounted()) {
          setTerminalData(data);
          setIsDataLoading(false);

          if (meta !== undefined) {
            setMeta(meta);
          }
        }
      } catch (error) {
        if (isMounted()) {
          setIsDataLoading(false);
        }
        throw error;
      }
    },
    [findAllTerminals, isMounted, zoneId]
  );

  const getTariffData = useCallback(
    async (page: number) => {
      try {
        if (isMounted()) {
          setIsDataLoading(true);
        }
        if (!masterTerminalId) {
          return;
        }
        const { data, meta } = await getTariffs({
          terminalId: masterTerminalId,
          filter: [
            {
              value: masterTerminalId.toString(),
              rule: FilterRule.LIKE,
              property: 'terminal_id',
            },
          ],
          page,
          size: PaginationSize.STANDARD,
        });
        if (isMounted()) {
          setTariffData(data);
          setIsDataLoading(false);

          if (meta !== undefined) {
            setMeta(meta);
          }
        }
      } catch (error) {
        if (isMounted()) {
          setIsDataLoading(false);
        }
        throw error;
      }
    },
    [getTariffs, isMounted, masterTerminalId]
  );

  useEffect(() => {
    getTerminalData(1);
  }, [getTerminalData]);

  useEffect(() => {
    getTariffData(1);
  }, [getTariffData, getTerminalData]);

  const [openTerminalDetailsModal, closeTerminalDetailsModal] = useGlobalModal(
    () => (
      <GlobalModal isOpen>
        <TariffDetailsModal
          closeParentModal={closeTerminalDetailsModal}
          tariff={tariffData[0]}
          onTariffUpdate={onTariffChange}
        />
      </GlobalModal>
    )
  );

  const sendTerminalOperation = useCallback(
    async (id: number) => {
      try {
        if (isMounted()) {
          setIsDataLoading(true);
        }
        await operateTerminal(id);
        setIsDataLoading(false);
      } catch (error) {
        if (isMounted()) {
          setIsDataLoading(false);
        }
        throw error;
      }
    },
    [isMounted, operateTerminal]
  );
  const removeItem = useCallback(
    (data?: ZoneModel) => {
      if (!data) {
        return;
      }
      openRemoveModal(data);
    },
    [openRemoveModal]
  );
  const isMasterOnline = useMemo(
    () =>
      terminalData.some(
        (terminal) =>
          terminal.isMaster && terminal.status === TerminalStatus.ONLINE
      ),
    [terminalData]
  );
  const InfoMessage = useMemo(() => {
    if (cameras.length > 0) {
      return 'If activated, everyone will be allowed to enter this zone.';
    }
    return 'This feature is enabled when ANPR camera is installed.';
  }, [cameras]);
  return (
    <StyledWrapper>
      <StyledDiv id={id === zoneId ? elementId : undefined}>
        <TextField
          label='Zone name*'
          placeholder='Zone name'
          type='text'
          name={`zoneInfo.${index}.name`}
          short
          zoneView
          disabled={userIsOperater}
        />
        <StyledIcon
          className={toggle ? ArrowIcons.UP : ArrowIcons.DOWN}
          onClick={onClick}
        />
      </StyledDiv>
      {toggle && (
        <Card zoneView>
          <ContainerDiv>
            <ProductsColumnDiv>
              {userHasCamera && (
                <ToggleSwitchForFormik
                  name={`zoneInfo.${index}.isPrivate`}
                  label='Public entrance'
                  condition={false}
                  info={InfoMessage}
                  disabled={cameras.length === 0 || userIsOperater}
                  zoneView
                />
              )}
              <WrapperDiv>
                <ZonesProducts
                  zone={data}
                  onCameraNameChange={onCameraNameChange}
                  onProductNameChange={onProductNameChange}
                />
              </WrapperDiv>
              <Line />
              <DeleteDiv onClick={() => removeItem(data)}>
                <StyledDeleteIcon className='pa-delete' />
                <StyledP>Delete Zone</StyledP>
              </DeleteDiv>
            </ProductsColumnDiv>

            <TerminalColumnDiv>
              <StyledNavLink
                linkTo={`/parking/lots/${id}/zones/${masterTerminalId}/sessions`}
                disabled={!isMasterOnline}
                tooltipMessage={'Master terminal is offline'}
                linkText={'List of sessions'}
              />
              {process.env.REACT_APP_SHOW_TARIFF === 'true' && (
                <Card directionRow>
                  {tariffData.length < 1 ? (
                    <Button
                      isLoading={isDataLoading}
                      type='button'
                      size={ButtonSize.MEDIUM}
                      small
                      style={{
                        paddingInline: '40px',
                      }}
                      onClick={() => {
                        openTerminalDetailsModal();
                      }}
                    >
                      Setup tariff
                    </Button>
                  ) : (
                    <TariffTable
                      name={tariffData[0].name}
                      startsAt={tariffData[0].startsAt}
                      endsAt={tariffData[0].endsAt}
                      price={tariffData[0].price}
                      isLoading={isDataLoading}
                      openModal={() => {
                        openTerminalDetailsModal();
                      }}
                    />
                  )}
                </Card>
              )}
              <TerminalList
                terminals={terminalData}
                isLoading={isDataLoading}
                sendTerminalOperation={sendTerminalOperation}
                onTerminalNameChange={onTerminalNameChange}
              />
              {meta.total >= PaginationItemDisplay.DISPLAYED_ITEMS && (
                <Pagination meta={meta} getData={getTerminalData} />
              )}
            </TerminalColumnDiv>
          </ContainerDiv>
        </Card>
      )}
    </StyledWrapper>
  );
}

const StyledDiv = styled.div`
  background-color: ${Color.TEXT_DARKER};
  padding: 15px 10px;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  scroll-margin-top: 80px;
  @media (max-width: 867px) {
    scroll-margin-top: 100px;
  }
  @media (max-width: 700px) {
    scroll-margin-top: 200px;
  }
`;

const DeleteDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0 0 5px;
  cursor: pointer;
  color: ${Color.PRIMARY_BRAND};
  width: fit-content;
`;

const StyledIcon = styled.i`
  display: flex;
  width: 60px;
  height: 40px;
  margin-right: 10px;
  cursor: pointer;
  color: ${Color.BACKGROUND_LIGHTER};
  align-items: center;
  justify-content: flex-end;
  font-size: 18px;
`;

const StyledDeleteIcon = styled.i`
  display: flex;
  margin-right: 10px;
  font-size: 18px;
`;

const StyledP = styled.p`
  display: flex;
  margin-right: 10px;
  font-size: 14px;
`;

const StyledWrapper = styled.div`
  :last-child {
    margin-bottom: 40px;
  }
`;

const WrapperDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ContainerDiv = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 867px) {
    flex-direction: column;
  }
`;

const ProductsColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  min-width: 400px;
  margin-right: 30px;
`;

const TerminalColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 500px;
  //
`;
