function calculateTimeDuration(
  startTime: Date,
  endTime: Date = new Date()
): string {
  const diffMs = Math.abs(endTime.getTime() - startTime.getTime()); // Difference in milliseconds

  const totalHours = Math.floor(diffMs / (1000 * 60 * 60)); // Convert entire difference into hours
  const minutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60)); // Remaining minutes

  return `${totalHours} hours, ${minutes} minutes`;
}
export default calculateTimeDuration;
