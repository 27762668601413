import React from 'react';
import Table from 'src/shared/components/Table';
import Color from 'src/shared/constants/Color';
import { ProductP, StyledIcon } from 'src/tariffs/components/ToggleCard';
// import styled from 'styled-components';

export interface TariffListProps {
  name: string;
  startsAt: string;
  endsAt: string;
  price: number;
  isLoading: boolean;
  openModal: () => void;
}

export default function TariffTable(data: TariffListProps) {
  const { name, startsAt, endsAt, price, isLoading, openModal } = data;

  return (
    <>
      <Table isLoading={isLoading}>
        <Table.Head>
          <Table.Row>
            <Table.Header>TARIFF NAME</Table.Header>
            <Table.Header>HOURS</Table.Header>
            <Table.Header>PRICE</Table.Header>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          <Table.Row>
            <Table.Cell>{name}</Table.Cell>
            <Table.Cell>{`${startsAt} - ${endsAt}`}</Table.Cell>
            <Table.Cell>{`${price} $ / per hour`}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <ProductP onClick={openModal} data-testid={'edit-button'}>
        <StyledIcon
          className='pa-edit'
          style={{ color: Color.PRIMARY_BRAND, fontSize: 20 }}
        />
      </ProductP>
    </>
  );
}
