import type TerminalsQuery from '../models/TermianlsQuery';
import type TermianlsQueryValues from '../models/TermianlsQueryValues';

export function toTerminalQuery(data: TermianlsQueryValues): TerminalsQuery {
  const { zoneId, page, size, filter } = data;

  return {
    zone_id: zoneId,
    filter: filter
      ? `${filter[0].property}:${filter[0].rule}:${filter[0].value}`
      : undefined,
    page,
    size,
  };
}
