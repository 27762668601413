import type { FormikHelpers } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import type AssignTerminalFormValues from 'src/lots/models/AssignTerminalFormValues';
import type Terminal from 'src/lots/models/Terminal';
import type TerminalUpdateFormValues from 'src/lots/models/TerminalUpdateFormValues';
import useLotService from 'src/lots/services/useLotService';
import useTerminalsService from 'src/lots/services/useTerminalService';
import useZoneService from 'src/lots/services/useZoneService';
import Form from 'src/shared/components/Form';
import { useGlobalSuccessModal } from 'src/shared/components/Modals/GlobalSuccessModal';
import useIsMounted from 'src/shared/hooks/useIsMounted';
import { FilterRule } from 'src/shared/models/FilterRule';
import type Option from 'src/shared/models/Option';
import { isNotString } from 'src/shared/utils/checks';
import * as yup from 'yup';
import AssignTerminalForm from '../AssignTerminalForm/AssignTerminalForm';

export interface AssignTerminalModalProps {
  accountId: number;
  closeParentModal: () => void;
  onAssignTerminal: (terminal: Terminal) => void;
}

export const assignTerminalInitialValues: AssignTerminalFormValues = {
  terminalDeviceId: null,
  name: '',
  zone: null,
  electronicDeviceId: '',
};

export const assignTerminalValidationSchema = yup.object().shape({
  terminalDeviceId: yup
    .object()
    .nullable()
    .required('Terminal Device ID is a required field'),
  electronicDeviceId: yup.object().nullable(),
  name: yup.string().required('Name is a required field'),
  zone: yup.object().nullable().required('Zone is a required field'),
});

interface lotAndZoneData {
  name: string;
  zoneId: number;
}

export default function AssignTerminalModal(props: AssignTerminalModalProps) {
  const { accountId, closeParentModal, onAssignTerminal } = props;
  const { updateTerminal } = useTerminalsService();
  const { findAllLotsParklioInternal } = useLotService();
  const { findAllZonesParklioInternal } = useZoneService();
  const { findAllTerminals } = useTerminalsService();
  const [, setIsDataLoading] = useState(false);
  const [lotAndZoneData, setLotAndZoneData] = useState<lotAndZoneData[]>([]);
  const [zoneOptions, setZoneOptions] = useState<Option[]>([]);
  const isMounted = useIsMounted();
  const { openGlobalSuccessModal } = useGlobalSuccessModal({
    closeParentModal: closeParentModal,
    message: 'Terminal was successfully assigned!',
  });

  const onSubmit = useCallback(
    async (
      values: AssignTerminalFormValues,
      { setErrors }: FormikHelpers<AssignTerminalFormValues>
    ) => {
      const { terminalDeviceId, name, zone, electronicDeviceId } = values;
      let id: number | undefined = 0;
      if (terminalDeviceId) {
        try {
          const response = await findAllTerminals({
            filter: [
              {
                value: terminalDeviceId.label,
                rule: FilterRule.LIKE,
                property: 'device_id',
              },
            ],
          });
          id = response.data.find(
            (item) => item.deviceId === terminalDeviceId.label
          )?.id;
        } catch (error) {
          if (isMounted()) {
            setIsDataLoading(false);
          }
          throw error;
        }
      }
      const updateValues: TerminalUpdateFormValues = {
        terminalDeviceId: id?.toString(),
        zoneId:
          zone !== null
            ? lotAndZoneData.find((item) => item.name === zone.label)?.zoneId
            : undefined,
        name: name,
        electronicDeviceId: electronicDeviceId || undefined,
      };
      try {
        if (updateValues.terminalDeviceId) {
          const response = await updateTerminal(
            parseInt(updateValues.terminalDeviceId),
            updateValues
          );
          onAssignTerminal(response);
        }
        openGlobalSuccessModal();
      } catch (error: any) {
        if (isMounted()) {
          if (isNotString(error) && error.code === undefined) {
            setErrors(error);
            return;
          }
        }
      }
    },
    [
      findAllTerminals,
      isMounted,
      lotAndZoneData,
      onAssignTerminal,
      openGlobalSuccessModal,
      updateTerminal,
    ]
  );

  useEffect(() => {
    const getData = async () => {
      try {
        if (!accountId) {
          return;
        }
        if (isMounted()) {
          setIsDataLoading(true);
        }

        const { data } = await findAllLotsParklioInternal({ accountId });

        const lotsData: lotAndZoneData[] = [];
        data.map(async (lot) => {
          const { data } = await findAllZonesParklioInternal({ lotId: lot.id });

          data.map((zone) => {
            lotsData.push({
              name: `${lot.name} - ${zone.name}`,
              zoneId: zone.id,
            });
          });
          const zoneOptions: Option[] = lotsData.map((item, index) => ({
            key: index,
            label: item.name,
          }));
          if (isMounted()) {
            setIsDataLoading(false);
            setLotAndZoneData(lotsData);
            setZoneOptions(zoneOptions);
          }
        });
      } catch (error) {
        if (isMounted()) {
          setIsDataLoading(false);
        }
        throw error;
      }
    };

    getData();
  }, [
    accountId,
    findAllLotsParklioInternal,
    findAllZonesParklioInternal,
    isMounted,
  ]);

  return (
    <>
      <Form
        name='assignTerminal'
        validationSchema={assignTerminalValidationSchema}
        initialValues={assignTerminalInitialValues}
        onSubmit={onSubmit}
      >
        <AssignTerminalForm
          accountId={accountId}
          isLoading={false}
          closeParentModal={closeParentModal}
          zoneOptions={zoneOptions}
        />
      </Form>
    </>
  );
}
