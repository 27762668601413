import type Payment from '../models/Payment';
import type PaymentResponse from '../models/PaymentResponse';

function ToPayment(props: PaymentResponse): Payment {
  const {
    amount_due,
    amount_paid,
    currency,
    error,
    paid_at,
    payment_id,
    payment_method,
    provider_payment_id,
    provider_transaction_id,
    session_id,
    success,
  } = props;
  return {
    amountDue: amount_due,
    amountPaid: amount_paid,
    currency,
    error,
    paidAt: paid_at,
    paymentId: payment_id,
    paymentMethod: payment_method,
    providerPaymentId: provider_payment_id,
    providerTransactionId: provider_transaction_id,
    sessionId: session_id,
    success,
  };
}
export default ToPayment;
