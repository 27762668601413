import type { FormikHelpers } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import TerminalStatus from 'src/lots/constants/TerminalStatus';
import type Terminal from 'src/lots/models/Terminal';
import type TerminalUpdateFormValues from 'src/lots/models/TerminalUpdateFormValues';
import useTerminalsService from 'src/lots/services/useTerminalService';
import ExitButton from 'src/shared/components/Buttons/ExitButton';
import Form from 'src/shared/components/Form';
import { useGlobalFailureModal } from 'src/shared/components/Modals/GlobalFailureModal';
import { useGlobalSuccessModal } from 'src/shared/components/Modals/GlobalSuccessModal';
import Spinner from 'src/shared/components/Spinner';
import Tabs from 'src/shared/components/Tabs';
import useIsMounted from 'src/shared/hooks/useIsMounted';
import { isNotString } from 'src/shared/utils/checks';
import styled from 'styled-components';
import * as yup from 'yup';
import TerminalSystemControlForm from '../TerminalSystemControlForm/TerminalSystemControlForm';
import TerminalUpdateForm from '../TerminalUpdateForm/TerminalUpdateForm';

export interface TerminalDetailsModalProps {
  terminal: Terminal | undefined;
  closeParentModal: () => void;
  onTerminalNameChange: (name: string, id: number) => void;
}

const defaultFormValues: TerminalUpdateFormValues = {
  name: '',
};

const validationSchema = yup.object().shape({
  name: yup.string().required('Name is a required field'),
});

export default function TerminalDetailsModal(props: TerminalDetailsModalProps) {
  const { closeParentModal, terminal, onTerminalNameChange } = props;
  const isMounted = useIsMounted();
  const [initialValues, setInitialValues] =
    useState<TerminalUpdateFormValues>(defaultFormValues);
  const [isInProgress, setIsInProgress] = useState(false);
  const [messageSuccess, setMessageSuccess] = useState('');
  const [shouldRefreshPage, setShouldRefreshPage] = useState(false);
  const [deviceInProgress, setDeviceInProgress] = useState(false);
  const { rebootTerminal } = useTerminalsService();
  const { openGlobalSuccessModal } = useGlobalSuccessModal({
    closeParentModal,
    message: messageSuccess,
    refreshPage: shouldRefreshPage,
  });
  const { updateTerminal } = useTerminalsService();
  const { openGlobalFailureModal, setMessage } = useGlobalFailureModal({});

  const onTerminalReboot = useCallback(async () => {
    try {
      if (!terminal?.id) {
        return;
      }
      if (isMounted()) {
        setDeviceInProgress(true);
      }
      await rebootTerminal(terminal.id);
    } catch (error) {
      if (isMounted()) {
        setDeviceInProgress(false);
      }
      throw error;
    }
  }, [isMounted, rebootTerminal, terminal?.id]);

  useEffect(() => {
    if (terminal?.name) {
      setInitialValues({ name: terminal.name });
    }
  }, [terminal?.name]);

  const onTerminalFormSubmit = useCallback(
    async (
      values: TerminalUpdateFormValues,
      { setErrors, resetForm }: FormikHelpers<TerminalUpdateFormValues>
    ) => {
      try {
        if (!terminal?.id) {
          return;
        }
        setIsInProgress(true);
        if (values.name) onTerminalNameChange(values.name, terminal.id);
        await updateTerminal(terminal.id, values);

        if (isMounted()) {
          resetForm({});
          setMessageSuccess('Terminal updated successfully!');
          openGlobalSuccessModal();
          setIsInProgress(false);
          setShouldRefreshPage(false);
        }
      } catch (error: any) {
        if (isMounted()) {
          setIsInProgress(false);

          if (isNotString(error) && error.code === undefined) {
            setErrors(error);
            return;
          }
          setMessage(error);
          openGlobalFailureModal();
        }
      }
    },
    [
      terminal?.id,
      updateTerminal,
      isMounted,
      openGlobalSuccessModal,
      onTerminalNameChange,
      setMessage,
      openGlobalFailureModal,
    ]
  );

  return (
    <>
      {isInProgress && (
        <StyledSpinner>
          <Spinner primary small />
          <StyledSpan>Processing...</StyledSpan>
        </StyledSpinner>
      )}
      <ExitButton onClick={closeParentModal} />
      <Tabs>
        <Tabs.Panel name='details' label='Details'>
          <Form
            name='terminal'
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onTerminalFormSubmit}
          >
            <TerminalUpdateForm
              details={terminal}
              closeParentModal={closeParentModal}
            />
          </Form>
        </Tabs.Panel>

        <Tabs.Panel name='systemControl' label='System Control'>
          <TerminalSystemControlForm
            onClick={onTerminalReboot}
            deviceInProgress={deviceInProgress}
            status={terminal?.status || TerminalStatus.OFFLINE}
          />
        </Tabs.Panel>
      </Tabs>
    </>
  );
}

const StyledSpinner = styled.div`
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 10000;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  border-radius: 10px;
`;

const StyledSpan = styled.span`
  margin-left: 10px;
`;
