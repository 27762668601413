import type TariffCreateRequest from '../models/TariffCreateParams';
import type TariffFormValues from '../models/TariffFormValues';

export function toTariffCreateParams(
  data: TariffFormValues
): TariffCreateRequest {
  const {
    name,
    startsAt,
    endsAt,
    price,
    entryFreePeriod,
    gracePeriod,
    gratisTime,
    daysOfWeek,
    overstay,
  } = data;

  return {
    name: name || '',
    starts_at: startsAt || '',
    ends_at: endsAt || '',
    price: price || 0,
    entry_free_period: entryFreePeriod || 0,
    grace_period: gracePeriod || 0,
    gratis_time: gratisTime || 0,
    days_of_week: daysOfWeek || [],
    overstay: overstay || false,
  };
}
