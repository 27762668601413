import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import AnprCameraParklioInternalList from 'src/anpr/screens/AnprCameraParklioInternalList';
import DetectLot from 'src/detection/screens/DetectLot';
import DetectLotList from 'src/detection/screens/DetectLotList';
import useAccountService from 'src/home/services/useAccountService';
import type Lot from 'src/lots/models/Lot';
import TerminalsParklioInternalList from 'src/lots/screens/TerminalsParklioInternalList/TerminalsParklioInternalList';
import useLotService from 'src/lots/services/useLotService';
import AccountInvoiceEdit from 'src/settings/screens/AccountInvoiceEdit';
import AccountLicenseList from 'src/settings/screens/AccountLicenseList';
import { useGlobalConfirmationModal } from 'src/shared/components/Modals/GlobalConfirmationModal';
import { useGlobalFailureModal } from 'src/shared/components/Modals/GlobalFailureModal';
import { useGlobalSuccessModal } from 'src/shared/components/Modals/GlobalSuccessModal';
import Color from 'src/shared/constants/Color';
import SessionStorageKey from 'src/shared/constants/SessionStorageKey';
import useIsMounted from 'src/shared/hooks/useIsMounted';
import styled from 'styled-components';
import AccountInfoEdit from '../../settings/screens/AccountInfoEdit';
import AccountUsersList from '../../settings/screens/AccountUserList';
import Breadcrumb from '../../shared/components/Breadcrumb';
import Content from '../../shared/components/Content';
import Main from '../../shared/components/Main';
import SideNavigation from '../../shared/components/SideNavigation';
import AppRole from '../../shared/constants/AppRole';
import useUserHasRole from '../../shared/hooks/useUserHasRole';

export default function Accounts() {
  const userHasRole = useUserHasRole();
  const { path, params } = useRouteMatch<{ accountId?: string }>();
  const { pathname } = useLocation();
  const { accountId } = params;
  const { removeAccount } = useAccountService();
  const isMounted = useIsMounted();
  const { findOneLot } = useLotService();
  const [lot, setLot] = useState<Lot>();

  const lotId = useMemo(() => {
    const ids = pathname.split('/');
    const id = ids.pop();
    const url = ids.join('/');

    if (url === `/settings/account/${accountId}/detect` && id) {
      return parseInt(id);
    }

    return undefined;
  }, [pathname, accountId]);

  const userCanReadAccountInfo = useMemo(() => {
    const roles =
      AppRole.PARKLIO_INTERNAL_SUPER_ADMIN |
      AppRole.PARKLIO_INTERNAL_ADMIN |
      AppRole.PMS_SUPER_ADMIN;

    return userHasRole(roles);
  }, [userHasRole]);

  const userIsSuperAdmin = useMemo(() => {
    const roles =
      AppRole.PARKLIO_INTERNAL_SUPER_ADMIN | AppRole.PMS_SUPER_ADMIN;

    return userHasRole(roles);
  }, [userHasRole]);

  const userCanReadAccountUsers = useMemo(() => {
    const roles =
      AppRole.PARKLIO_INTERNAL_SUPER_ADMIN |
      AppRole.PARKLIO_INTERNAL_ADMIN |
      AppRole.PMS_SUPER_ADMIN |
      AppRole.PMS_ADMIN;

    return userHasRole(roles);
  }, [userHasRole]);

  const userCanReadAccount = useMemo(() => {
    const roles =
      AppRole.PARKLIO_INTERNAL_SUPER_ADMIN |
      AppRole.PARKLIO_INTERNAL_ADMIN |
      AppRole.PMS_SUPER_ADMIN |
      AppRole.PMS_ADMIN;

    return userHasRole(roles);
  }, [userHasRole]);

  const userCanReadAccountLicenses = useMemo(() => {
    const roles =
      AppRole.PARKLIO_INTERNAL_SUPER_ADMIN |
      AppRole.PARKLIO_INTERNAL_ADMIN |
      AppRole.PMS_SUPER_ADMIN |
      AppRole.PMS_ADMIN |
      AppRole.PMS_OPERATOR;

    return userHasRole(roles);
  }, [userHasRole]);

  const userIsParklioInternalSuperAdmin = useMemo(() => {
    const roles = AppRole.PARKLIO_INTERNAL_SUPER_ADMIN;
    return userHasRole(roles);
  }, [userHasRole]);

  const userIsParklioInternal = useMemo(() => {
    const roles =
      AppRole.PARKLIO_INTERNAL_SUPER_ADMIN |
      AppRole.PARKLIO_INTERNAL_ADMIN |
      AppRole.PARKLIO_INTERNAL_OPERATOR;
    return userHasRole(roles);
  }, [userHasRole]);

  const userCanDeleteAccount =
    userIsParklioInternalSuperAdmin &&
    parseInt(accountId || '') !==
      parseInt(sessionStorage.getItem(SessionStorageKey.ID) || '');
  const loggedUserIsParklioInternal =
    userIsParklioInternal &&
    parseInt(accountId || '') ===
      parseInt(sessionStorage.getItem(SessionStorageKey.ID) || '');

  const { openGlobalSuccessModal } = useGlobalSuccessModal({
    message: 'Account deleted successfully!',
    redirect: true,
  });

  const { openGlobalFailureModal, setMessage } = useGlobalFailureModal({});

  const onRemove = useCallback(async () => {
    try {
      if (accountId) {
        await removeAccount(parseInt(accountId));
        if (isMounted()) {
          openGlobalSuccessModal();
        }
      }
    } catch (error: any) {
      if (isMounted()) {
        setMessage(error);
        openGlobalFailureModal();
      }
    }
  }, [
    isMounted,
    openGlobalSuccessModal,
    openGlobalFailureModal,
    setMessage,
    accountId,
    removeAccount,
  ]);

  const { openGlobalConfirmationModal } = useGlobalConfirmationModal({
    action: onRemove,
    message:
      'Are you sure you want to delete this account? This action is permanent.',
  });

  useEffect(() => {
    const getData = async () => {
      if (!lotId || !userIsParklioInternal) {
        return;
      }

      const response = await findOneLot(lotId);

      if (isMounted()) {
        setLot(response);
      }
    };

    getData();
  }, [findOneLot, isMounted, lotId, userIsParklioInternal]);

  return (
    <Content>
      <Switch>
        <Route exact path={`/settings/account/${accountId}/info`}>
          <Breadcrumb to='/'>
            <Breadcrumb.Link to='/'>Dashboard</Breadcrumb.Link>
            <Breadcrumb.Link to={`/settings/account/${accountId}/info`} last>
              Account Info
            </Breadcrumb.Link>
          </Breadcrumb>
        </Route>
        <Route exact path={`/settings/account/${accountId}/users`}>
          <Breadcrumb to='/'>
            <Breadcrumb.Link to='/'>Dashboard</Breadcrumb.Link>
            <Breadcrumb.Link to={`/settings/account/${accountId}/users`} last>
              Parking Managers
            </Breadcrumb.Link>
          </Breadcrumb>
        </Route>
        <Route exact path={`/settings/account/${accountId}/licenses`}>
          <Breadcrumb to='/'>
            <Breadcrumb.Link to='/'>Dashboard</Breadcrumb.Link>
            <Breadcrumb.Link
              to={`/settings/account/${accountId}/licenses`}
              last
            >
              Licenses
            </Breadcrumb.Link>
          </Breadcrumb>
        </Route>
        <Route exact path={`/settings/account/${accountId}/anpr-subscription`}>
          <Breadcrumb to='/'>
            <Breadcrumb.Link to='/'>Dashboard</Breadcrumb.Link>
            <Breadcrumb.Link
              to={`/settings/account/${accountId}/anpr-subscription`}
              last
            >
              ANPR Image Storage
            </Breadcrumb.Link>
          </Breadcrumb>
        </Route>
        <Route exact path={`/settings/account/${accountId}/invoice`}>
          <Breadcrumb to='/'>
            <Breadcrumb.Link to='/'>Dashboard</Breadcrumb.Link>
            <Breadcrumb.Link to={`/settings/account/${accountId}/invoice`} last>
              Invoice Setup
            </Breadcrumb.Link>
          </Breadcrumb>
        </Route>
        <Route exact path={`/settings/account/${accountId}/detect`}>
          <Breadcrumb to='/'>
            <Breadcrumb.Link to='/'>Dashboard</Breadcrumb.Link>
            <Breadcrumb.Link to={`/settings/account/${accountId}/detect`} last>
              Lots
            </Breadcrumb.Link>
          </Breadcrumb>
        </Route>
        <Route exact path={`/settings/account/${accountId}/detect/:lotId`}>
          <Breadcrumb to='/'>
            <Breadcrumb.Link to='/'>Dashboard</Breadcrumb.Link>
            <Breadcrumb.Link to={`/settings/account/${accountId}/detect`}>
              Lots
            </Breadcrumb.Link>
            <Breadcrumb.Link
              to={`/settings/account/${accountId}/detect/:lotId`}
              last
            >
              {lot?.name}
            </Breadcrumb.Link>
          </Breadcrumb>
        </Route>
      </Switch>
      <Main>
        {userCanReadAccount && (
          <Route path={`${path}/`}>
            <StyledWrapper>
              <SideNavigation>
                {userCanReadAccountInfo && (
                  <SideNavigation.Link
                    to={`/settings/account/${accountId}/info`}
                  >
                    Info
                  </SideNavigation.Link>
                )}
                {userCanReadAccountLicenses && !loggedUserIsParklioInternal && (
                  <SideNavigation.Link
                    to={`/settings/account/${accountId}/licenses`}
                  >
                    Licenses
                  </SideNavigation.Link>
                )}
                {userIsSuperAdmin && (
                  <SideNavigation.Link
                    to={`/settings/account/${accountId}/invoice`}
                  >
                    Invoice Setup
                  </SideNavigation.Link>
                )}
                {userCanReadAccountUsers && (
                  <SideNavigation.Link
                    to={`/settings/account/${accountId}/users`}
                  >
                    Parking Managers
                  </SideNavigation.Link>
                )}
                {userIsParklioInternal && (
                  <SideNavigation.Link
                    to={`/settings/account/${accountId}/anpr-subscription`}
                  >
                    ANPR Image Storage
                  </SideNavigation.Link>
                )}
                {userIsParklioInternal && (
                  <SideNavigation.Link
                    to={`/settings/account/${accountId}/detect`}
                  >
                    Detection
                  </SideNavigation.Link>
                )}
                {userIsParklioInternal && (
                  <SideNavigation.Link
                    to={`/settings/account/${accountId}/terminals`}
                  >
                    Terminals
                  </SideNavigation.Link>
                )}
              </SideNavigation>
              {userCanDeleteAccount && (
                <StyledContainer onClick={openGlobalConfirmationModal}>
                  <StyledIcon className='pa-delete' />
                  Delete account
                </StyledContainer>
              )}
            </StyledWrapper>
            <Content>
              <Switch>
                {userCanReadAccountInfo && (
                  <Route exact path={`${path}/info`}>
                    <AccountInfoEdit accountId={parseInt(accountId || '')} />
                  </Route>
                )}
                {userCanReadAccountLicenses && !loggedUserIsParklioInternal && (
                  <Route exact path={`${path}/licenses`}>
                    <AccountLicenseList accountId={parseInt(accountId || '')} />
                  </Route>
                )}
                {userIsSuperAdmin && (
                  <Route exact path={`${path}/invoice`}>
                    <AccountInvoiceEdit accountId={parseInt(accountId || '')} />
                  </Route>
                )}
                {userCanReadAccountUsers && (
                  <Route exact path={`${path}/users`}>
                    <AccountUsersList accountId={parseInt(accountId || '')} />
                  </Route>
                )}
                {userIsParklioInternal && (
                  <Route exact path={`${path}/detect`}>
                    <DetectLotList accountId={parseInt(accountId || '')} />
                  </Route>
                )}
                {userIsParklioInternal && (
                  <Route exact path={`${path}/detect/:lotId`}>
                    <DetectLot lot={lot} />
                  </Route>
                )}
                {userIsParklioInternal && (
                  <Route path={`${path}/anpr-subscription`}>
                    <AnprCameraParklioInternalList
                      accountId={parseInt(accountId || '')}
                    />
                  </Route>
                )}
                {userIsParklioInternal && (
                  <Route path={`${path}/terminals`}>
                    <TerminalsParklioInternalList
                      accountId={parseInt(accountId || '')}
                    />
                  </Route>
                )}
                <Route>
                  <h3>404 PAGE</h3>
                </Route>
              </Switch>
            </Content>
          </Route>
        )}
      </Main>
    </Content>
  );
}

const StyledWrapper = styled.div`
  flex: 1;
  flex-direction: column;
`;

const StyledContainer = styled.div`
  display: flex;
  color: ${Color.ERROR};
  justify-content: center;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  cursor: pointer;
  width: 90%;
  margin-top: -20px;

  @media (max-width: 768px) {
    margin-top: 20px;
  }
`;

const StyledIcon = styled.i`
  font-size: 18px;
  line-height: 20px;
  margin: 0px 10px 0px 0px;
`;
