import { useField } from 'formik';
import React from 'react';
import styled, { css } from 'styled-components';
import Color from '../constants/Color';
import RadioButton from './RadioButton';

interface RadioButtonFieldProps {
  name: string;
  label: string;
  value: string | number;
  disabled?: boolean;
  defaultChecked?: boolean;
  addLastChildMargin?: boolean;
  paddingInline?: boolean;
}

export default function RadioButtonField(props: RadioButtonFieldProps) {
  const {
    label,
    name,
    disabled,
    value,
    defaultChecked,
    addLastChildMargin,
    paddingInline,
  } = props;
  const [field] = useField({ name });
  const { checked, onChange } = field;

  return (
    <StyledWrapper
      addLastChildMargin={addLastChildMargin}
      blockPadding={paddingInline}
    >
      <RadioButton
        onChange={onChange}
        name={name}
        disabled={disabled}
        value={value}
        checked={checked}
        defaultChecked={defaultChecked}
      />
      <StyledLabel>{label}</StyledLabel>
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div<{
  addLastChildMargin?: boolean;
  blockPadding?: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 30px;
  ${(props) => {
    const { addLastChildMargin, blockPadding } = props;

    if (addLastChildMargin) {
      return css`
        :last-child {
          margin-bottom: 40px;
        }
      `;
    }
    if (blockPadding) {
      return css`
        padding-inline: 50px;
      `;
    }
    return css``;
  }}
`;

const StyledLabel = styled.label`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  color: ${Color.TEXT_DARKER};
`;
