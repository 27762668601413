import type LprCamera from 'src/anpr/models/LprCamera/LprCamera';
import type LprCameraResponse from 'src/anpr/models/LprCamera/LprCameraResponse';
import toLot from '../../lots/mappers/toLot';
import toLprImageStorageSubscription from './toLprImageStorageSubscription';

export default function toLprCamera(data: LprCameraResponse): LprCamera {
  const {
    id,
    device_id,
    device_serial,
    name,
    account_id,
    lot_id,
    zone_id,
    status,
    lpr_position,
    connected_device_type,
    mac,
    deleted_at,
    created_at,
    updated_at,
    storage_subscription_plan,
    lot,
    save_image,
  } = data;

  return {
    id,
    deviceId: device_id,
    deviceSerial: device_serial,
    name,
    accountId: account_id,
    lotId: lot_id,
    zoneId: zone_id,
    status,
    lprPosition: lpr_position,
    connectedDeviceType: connected_device_type,
    mac,
    deletedAt: deleted_at,
    createdAt: created_at,
    updatedAt: updated_at,
    storageSubscriptionPlan: storage_subscription_plan
      ? toLprImageStorageSubscription(storage_subscription_plan)
      : null,
    lot: lot ? toLot(lot) : undefined,
    saveImage: save_image,
  };
}
