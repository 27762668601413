import { useFormikContext } from 'formik';
import React from 'react';
import type Tariff from 'src/lots/models/Tariff';
import type TerminalUpdateFormValues from 'src/lots/models/TerminalUpdateFormValues';
import DetailsColumn from 'src/products/components/DetailsColumn';
import DetailsRow from 'src/products/components/DetailsRow';
import Button from 'src/shared/components/Button';
import Control from 'src/shared/components/Control';
import Label from 'src/shared/components/Label';
import Line from 'src/shared/components/Line';
import { useGlobalCancelModal } from 'src/shared/components/Modals/GlobalCancelModal';
import RadioButtonField from 'src/shared/components/RadioButtonField';
import Spinner from 'src/shared/components/Spinner';
import type { StyledCheckboxOptions } from 'src/shared/components/StyledRadioButtonsRow/StyledCheckboxRow';
import StyledCheckboxRow from 'src/shared/components/StyledRadioButtonsRow/StyledCheckboxRow';
import TextField from 'src/shared/components/TextField';
import Title from 'src/shared/components/Title';
import ButtonSize from 'src/shared/constants/ButtonSize';

export interface TariffEditFormProps {
  tariff?: Tariff;
  isLoading?: boolean;
  closeParentModal: () => void;
}
export default function TariffEditForm(props: TariffEditFormProps) {
  const { isLoading, closeParentModal } = props;
  const { isSubmitting, dirty } = useFormikContext<TerminalUpdateFormValues>();

  const { openGlobalCancelModal } = useGlobalCancelModal({
    closeParentModal,
  });

  const daysOfWeek: StyledCheckboxOptions[] = [
    { label: 'MON', value: 'Monday' },
    { label: 'TUE', value: 'Tuesday' },
    { label: 'WED', value: 'Wednesday' },
    { label: 'THU', value: 'Thursday' },
    { label: 'FRI', value: 'Friday' },
    { label: 'SAT', value: 'Saturday' },
    { label: 'SUN', value: 'Sunday' },
  ];

  return (
    <>
      {isLoading && <Spinner primary data-testid='spinner' />}
      <DetailsRow>
        <DetailsColumn>
          <Title short addBottomMargin>
            Edit Tariff
          </Title>
          <Line />
          <TextField
            label='Name'
            placeholder='Tariff name'
            type='text'
            name='name'
            small
          />
          <TextField
            label='Hourly price'
            placeholder='0'
            type='number'
            name='price'
            minValue={0}
            tiny
            description='$/per hour'
          />
          <TextField
            label='Entry free time interval'
            placeholder='0'
            type='number'
            name='entryTime'
            minValue={0}
            tiny
            description='min'
          />
          <TextField
            label='Exit free time interval'
            placeholder='0'
            type='number'
            name='gracePeriod'
            minValue={0}
            tiny
            description='min'
          />
          <TextField
            label='Gratis time'
            placeholder='0'
            type='number'
            name='gratisTime'
            minValue={0}
            tiny
            description='min'
          />
          <StyledCheckboxRow option={daysOfWeek} title='Days of week' />

          <Label>Working hours</Label>
          <DetailsRow>
            <TextField
              label='From'
              placeholder='00:00'
              type='text'
              name='startsAt'
              minValue={0}
              small
              maxLength={5}
              stacked
            />
            <TextField
              label='To'
              placeholder='00:00'
              type='text'
              name='endsAt'
              minValue={0}
              small
              stacked
            />
          </DetailsRow>
          <Label>When not active</Label>

          <DetailsRow>
            <RadioButtonField
              label='Forbid entry'
              name='overstay'
              value={0}
              defaultChecked
            />
            <RadioButtonField
              label='Allow entry'
              name='overstay'
              value={1}
              paddingInline
            />
          </DetailsRow>
        </DetailsColumn>
      </DetailsRow>

      <Control>
        <Button
          onClick={dirty ? openGlobalCancelModal : closeParentModal}
          size={ButtonSize.LARGE}
          type='button'
          disabled={isSubmitting}
        >
          Cancel
        </Button>
        <Button
          isLoading={isSubmitting}
          size={ButtonSize.LARGE}
          type='submit'
          primary
          disabled={isSubmitting}
        >
          Save
        </Button>
      </Control>
    </>
  );
}
