import React from 'react';
import type LprCamera from 'src/anpr/models/LprCamera/LprCamera';
import Card from 'src/shared/components/Card';
import Table from 'src/shared/components/Table';
import Title from 'src/shared/components/Title';
import ToggleSwitch from 'src/shared/components/ToggleSwitch';
import styled from 'styled-components';

export interface AnprCameraParklioInternalTableProps {
  data: LprCamera[];
  onChangeState: (id: number) => void;
  getData: () => void;
}

export default function AnprCameraParklioInternalTable(
  props: AnprCameraParklioInternalTableProps
) {
  const { data, onChangeState } = props;
  return (
    <Card>
      <StyledWrapper>
        <Title addBottomMargin bold>
          {data[0].lot?.name}
        </Title>
      </StyledWrapper>
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.Header>ID</Table.Header>
            <Table.Header>NAME</Table.Header>
            <Table.Header>DEVICE ID</Table.Header>
            <Table.Header colSpan={2}>SAVE IMAGE</Table.Header>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {data.map((camera) => (
            <Table.Row key={camera.id}>
              <Table.Cell>{camera.id}</Table.Cell>
              <Table.Cell>{camera.name}</Table.Cell>
              <Table.Cell>{camera.deviceId}</Table.Cell>
              <Table.Cell>
                <ToggleSwitch
                  state={camera.saveImage}
                  condition
                  onChange={() => {
                    onChangeState(camera.id);
                  }}
                  name=''
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Card>
  );
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 699px) {
    flex-direction: column;
  }
`;
