import React, { useState } from 'react';
import TerminalStatus from 'src/lots/constants/TerminalStatus';
import type Terminal from 'src/lots/models/Terminal';
import { getTerminalTypeLabel } from 'src/lots/utils/getTerminalTypeLabel';
import ProductTableIcon from 'src/products/components/ProductTableIcon';
import Button from 'src/shared/components/Button';
import Card from 'src/shared/components/Card';
import GlobalModal from 'src/shared/components/Modals/GlobalModal';
import StyledNoData from 'src/shared/components/StyledNoData';
import Table from 'src/shared/components/Table';
import ButtonSize from 'src/shared/constants/ButtonSize';
import { useGlobalModal } from 'src/shared/hooks/useGlobalModal';
import { ProductP } from 'src/tariffs/components/ToggleCard';
import styled from 'styled-components';
import TerminalDetailsModal from '../TerminalDetailsModal/TerminalDetailsModal';

export interface TerminalListProps {
  terminals: Terminal[];
  isLoading: boolean;
  sendTerminalOperation: (id: number) => void;
  onTerminalNameChange: (name: string, id: number) => void;
}

export default function TerminalList(data: TerminalListProps) {
  const { terminals, isLoading, sendTerminalOperation, onTerminalNameChange } =
    data;

  const [activeTerminal, setActiveTerminal] = useState<Terminal | undefined>();

  const [openTerminalDetailsModal, closeTerminalDetailsModal] = useGlobalModal(
    () => (
      <GlobalModal isOpen>
        <TerminalDetailsModal
          closeParentModal={closeTerminalDetailsModal}
          terminal={activeTerminal}
          onTerminalNameChange={onTerminalNameChange}
        />
      </GlobalModal>
    )
  );

  return (
    <Card>
      {data.terminals.length < 1 ? (
        <StyledNoData>No terminals</StyledNoData>
      ) : (
        <Table isLoading={isLoading}>
          <Table.Head>
            <Table.Row>
              <Table.Header> </Table.Header>
              <Table.Header>TERMINAL</Table.Header>
              <Table.Header>STATUS</Table.Header>
              <Table.Header>REMOTE CONTROL</Table.Header>
              <Table.Header />
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {terminals.map((data) => {
              const { id, status, name, type } = data;
              const openModal = () => {
                setActiveTerminal(data);
                openTerminalDetailsModal();
              };
              const isTerminalOffline = status === TerminalStatus.OFFLINE;

              return (
                <Table.Row disabled={isTerminalOffline} key={id}>
                  <Table.Cell>
                    <StyledDiv>{getTerminalTypeLabel(type)}</StyledDiv>
                  </Table.Cell>
                  <Table.Cell>
                    <ProductP key={id} onClick={openModal}>
                      <ProductTableIcon iconType={7} />
                      {name}
                    </ProductP>
                  </Table.Cell>
                  <Table.Cell>{status}</Table.Cell>
                  <Table.Cell>
                    <Button
                      disabled={isTerminalOffline}
                      type='button'
                      size={ButtonSize.TERMINAL}
                      onClick={() => {
                        sendTerminalOperation(id);
                      }}
                      primary
                    >
                      Open
                    </Button>
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      )}
    </Card>
  );
}
const StyledDiv = styled.div`
  color: black;
`;
