import type Profile from 'src/settings/models/Profile';
import type ProfileResponse from 'src/settings/models/ProfileResponse';
import toAccountUserRole from 'src/shared/mappers/toAccountUserRole';
import toAccountInfo from './toAccountInfo';

export default function toProfile(data: ProfileResponse): Profile {
  const {
    id,
    name,
    email,
    phone,
    account,
    account_user_role,
    custom_percentage,
    organisation_id,
  } = data;

  return {
    id,
    name,
    email,
    phone,
    role: { key: account_user_role.id, label: account_user_role.name },
    account: toAccountInfo(account),
    accountUserRole: toAccountUserRole(account_user_role),
    customPercentage: custom_percentage || null,
    organisationId: organisation_id || '',
  };
}
