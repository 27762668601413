import React, { useCallback, useState } from 'react';
import 'react-toggle/style.css';
import '../../assets/css/toggle.css';
import Status from 'src/products/constants/Status';
import useStatusLabel from 'src/products/hooks/useStatusLabel';
import ActionIcon from 'src/shared/constants/ActionIcon';
import ButtonSize from 'src/shared/constants/ButtonSize';
import DetailsStatus from '../../products/components/DetailsStatus';
import Button from './Button';
import Dropdown from './Dropdown';
import IconRow from './IconRow';
import {
  InfoIcon,
  LabelWrapper,
  StyledDescription,
  StyledErrorWrapper,
  StyledWrapper,
} from './InfoMessage';
import Label from './Label';

interface SystemControlButtonProps {
  onClick: () => void;
  name: string;
  info?: string;
  status?: string;
  deviceInProgress?: boolean;
}

export default function SystemControlButton(props: SystemControlButtonProps) {
  const { onClick, name, info, status, deviceInProgress } = props;

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const offline = status === Status.OFFLINE;
  const statusLabel = useStatusLabel(status);

  const open = useCallback(() => {
    setIsDropdownOpen(true);
  }, []);

  const close = useCallback(() => {
    setIsDropdownOpen(false);
  }, []);

  return (
    <StyledWrapper>
      <LabelWrapper addStatus>
        <Label>
          {name}
          {info && (
            <InfoIcon
              className='pa-info'
              onMouseEnter={open}
              onMouseLeave={close}
            />
          )}
        </Label>
        {status && (
          <DetailsStatus offline={offline} addMargin>
            {statusLabel}
          </DetailsStatus>
        )}
        <Button
          primary
          size={ButtonSize.MIDDLE}
          onClick={onClick}
          disabled={deviceInProgress || offline}
          isLoading={deviceInProgress}
        >
          <IconRow className={ActionIcon.REFRESH} />
          Reboot
        </Button>
      </LabelWrapper>
      {info && isDropdownOpen && (
        <Dropdown close={close} small>
          <StyledErrorWrapper>
            <StyledDescription>{info}</StyledDescription>
          </StyledErrorWrapper>
        </Dropdown>
      )}
    </StyledWrapper>
  );
}
