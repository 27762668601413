import type TerminalUpdateFormValues from '../models/TerminalUpdateFormValues';
import type TerminalUpdateParams from '../models/TerminalUpdateParams';

export function toTerminalUpdateParams(
  data: TerminalUpdateFormValues
): TerminalUpdateParams {
  const { name, terminalDeviceId, zoneId, electronicDeviceId } = data;

  return {
    name,
    device_id: terminalDeviceId,
    zone_id: zoneId,
    electronic_device_id: electronicDeviceId,
  };
}
