import type { FormikHelpers } from 'formik';
import React, { useCallback, useState } from 'react';
import type LicensePlateAllowFormValues from 'src/anpr/models/LotWhitelist/LicensePlateAllowFormValues';
import useLotWhitelistsService from 'src/anpr/services/useLotWhitelistsService';
import useProductService from 'src/products/services/useProductService';
import Button from 'src/shared/components/Button';
import Card from 'src/shared/components/Card';
import Form from 'src/shared/components/Form';
import { useGlobalFailureModal } from 'src/shared/components/Modals/GlobalFailureModal';
import GlobalModal from 'src/shared/components/Modals/GlobalModal';
import { useGlobalSuccessModal } from 'src/shared/components/Modals/GlobalSuccessModal';
import ButtonSize from 'src/shared/constants/ButtonSize';
import { useGlobalModal } from 'src/shared/hooks/useGlobalModal';
import useIsMounted from 'src/shared/hooks/useIsMounted';
import { FilterRule } from 'src/shared/models/FilterRule';
import { isNotString } from 'src/shared/utils/checks';
import * as yup from 'yup';
import AllowLicensePlateForm from './AllowLicensePlateForm';

import UploadWhitelistForm from './UploadWhitelistForm/UploadWhitelistForm';

const allowLicensePlateInitialValues: LicensePlateAllowFormValues = {
  licensePlates: '',
  email: '',
  name: '',
  barriers: [],
  startTime: null,
  endTime: null,
  isUnlimited: false,
};

const allowLicensePlateValidationSchema = yup.object().shape({
  licensePlates: yup.string().required('License Plate is a required field'),
  name: yup.string().notRequired(),
  email: yup.string().email().notRequired(),
  startTime: yup.date().nullable().required('Start Time is a required field'),
  isUnlimited: yup.boolean().notRequired(),
  endTime: yup
    .date()
    .nullable()
    .when('isUnlimited', {
      is: false,
      then: yup
        .date()
        .required('End Time is a required field')
        .min(yup.ref('startTime'), "End Time can't be before Start Time"),
    }),
});

interface AllowLicensePlateProps {
  lotId: number | undefined;
}

function AllowLicensePlate(props: AllowLicensePlateProps) {
  const { lotId } = props;
  const { allowLicensePlate, exportWhitelistFile } = useLotWhitelistsService();
  const { bindLicenses } = useProductService();
  const isMounted = useIsMounted();
  const [isLoadingFile, setIsLoadingFile] = useState(false);

  const [openAllowNewLicensePlateModal, closeAllowNewLicensePlateModal] =
    useGlobalModal(() => (
      <GlobalModal isOpen>
        <Form
          name='AllowLicensePlate'
          initialValues={allowLicensePlateInitialValues}
          validationSchema={allowLicensePlateValidationSchema}
          onSubmit={onAllowLicensePlateSubmit}
        >
          <AllowLicensePlateForm
            closeParentModal={closeAllowNewLicensePlateModal}
            lotId={lotId}
          />
        </Form>
      </GlobalModal>
    ));

  const [openUploadWhitelistFileModal, closeUploadWhitelistFileModal] =
    useGlobalModal(() => {
      if (!lotId) {
        return <></>;
      }
      return (
        <GlobalModal isOpen>
          <UploadWhitelistForm
            closeParentModal={closeUploadWhitelistFileModal}
            lotId={lotId.toString()}
          />
        </GlobalModal>
      );
    });

  const onExportFileClick = async () => {
    setIsLoadingFile(true);

    if (!lotId) {
      if (isMounted()) {
        setIsLoadingFile(false);
      }
      return;
    }
    try {
      await exportWhitelistFile({
        filter: [
          {
            property: 'lot_id',
            value: lotId.toString(),
            rule: FilterRule.EQUALS,
          },
        ],
      });
      if (isMounted()) {
        setIsLoadingFile(false);
      }
    } catch (error) {
      if (isMounted()) {
        setIsLoadingFile(false);
      }
      throw error;
    }
  };

  const { openGlobalSuccessModal: openAllowLicensePlateSuccessModal } =
    useGlobalSuccessModal({
      closeParentModal: closeAllowNewLicensePlateModal,
      message: 'License plate was successfully added!',
    });

  const {
    openGlobalFailureModal: openAllowLicensePlateFailureModal,
    setMessage: setAllowLicencePlateMessage,
  } = useGlobalFailureModal({});

  const onAllowLicensePlateSubmit = useCallback(
    async (
      values: LicensePlateAllowFormValues,
      { setErrors, resetForm }: FormikHelpers<LicensePlateAllowFormValues>
    ) => {
      try {
        if (!lotId) {
          return;
        }
        const response = await allowLicensePlate(lotId, values);
        const products = values.barriers.map((barrier) => barrier.key);

        if (products.length > 0) {
          const allowedLicenses = response.map((license) => license.licenseId);

          await bindLicenses({
            productIds: products,
            licenseIds: allowedLicenses,
          });
        }

        if (isMounted()) {
          resetForm({});
          openAllowLicensePlateSuccessModal();
        }
      } catch (error: any) {
        if (isMounted()) {
          if (isNotString(error) && error.code === undefined) {
            setErrors(error);
            return;
          }
          setAllowLicencePlateMessage(error);
          openAllowLicensePlateFailureModal();
        }
      }
    },
    [
      allowLicensePlate,
      isMounted,
      lotId,
      openAllowLicensePlateSuccessModal,
      openAllowLicensePlateFailureModal,
      setAllowLicencePlateMessage,
      bindLicenses,
    ]
  );

  return (
    <Card>
      <Button
        size={ButtonSize.MIDDLE}
        type='button'
        onClick={openAllowNewLicensePlateModal}
      >
        Allow lot access to plates
      </Button>
      <Button
        addVerticalMargin
        size={ButtonSize.MIDDLE}
        type='button'
        onClick={onExportFileClick}
        isLoading={isLoadingFile}
        addLeftMargin={15}
      >
        Export
      </Button>
      <Button
        addLeftMargin={15}
        size={ButtonSize.MIDDLE}
        type='button'
        onClick={openUploadWhitelistFileModal}
      >
        Import
      </Button>
    </Card>
  );
}

export default AllowLicensePlate;
