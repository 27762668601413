import React from 'react';
import DetailsColumn from 'src/products/components/DetailsColumn';
import DetailsRow from 'src/products/components/DetailsRow';
import SystemControlButton from 'src/shared/components/SystemControlButton';

export interface TerminalSystemControlFormProps {
  onClick: () => void;
  deviceInProgress: boolean;
  status: string;
}

export default function TerminalSystemControlForm(
  props: TerminalSystemControlFormProps
) {
  const { onClick, deviceInProgress, status } = props;

  return (
    <DetailsRow wider addMargin>
      <DetailsColumn>
        <SystemControlButton
          onClick={onClick}
          name='Reboot'
          status={status}
          deviceInProgress={deviceInProgress}
        />
      </DetailsColumn>
    </DetailsRow>
  );
}
