import { useFormikContext } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import type Terminal from 'src/lots/models/Terminal';
import type TerminalUpdateFormValues from 'src/lots/models/TerminalUpdateFormValues';
import useTerminalsService from 'src/lots/services/useTerminalService';
import DetailsColumn from 'src/products/components/DetailsColumn';
import DetailsRow from 'src/products/components/DetailsRow';
import Button from 'src/shared/components/Button';
import Control from 'src/shared/components/Control';
import Line from 'src/shared/components/Line';
import { useGlobalCancelModal } from 'src/shared/components/Modals/GlobalCancelModal';
import SelectField from 'src/shared/components/SelectField';
import Spinner from 'src/shared/components/Spinner';
import TextField from 'src/shared/components/TextField';
import Title from 'src/shared/components/Title';
import ButtonSize from 'src/shared/constants/ButtonSize';
import { FilterRule } from 'src/shared/models/FilterRule';
import type Option from 'src/shared/models/Option';

export interface AssignTerminalFormProps {
  accountId: number;
  isLoading: boolean;
  zoneOptions: Option[];
  closeParentModal: () => void;
}

export default function AssignTerminalForm(props: AssignTerminalFormProps) {
  const { isLoading, closeParentModal, zoneOptions } = props;
  const { isSubmitting, dirty } = useFormikContext<TerminalUpdateFormValues>();
  const { findAllTerminals } = useTerminalsService();
  const { openGlobalCancelModal } = useGlobalCancelModal({
    closeParentModal,
  });
  const [searchDeviceId, setSearchDeviceId] = useState('');
  const [searchLots, setSearchLots] = useState('');
  const [optionsDeviceIds, setOptionsDeviceIds] = useState<Option[]>([]);
  const [debouncedSearch, setDebouncedSearch] = useState(searchDeviceId);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(searchDeviceId);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchDeviceId]);

  const optionsLots: Option[] = useMemo(
    () =>
      zoneOptions
        .filter(({ label }) =>
          label.toLowerCase().includes(searchLots.toLowerCase())
        )
        .map(({ label, key }) => ({
          key: key,
          label: label,
        })),
    [zoneOptions, searchLots]
  );

  const getData = useCallback(async () => {
    try {
      let data: Terminal[] = [];
      try {
        if (debouncedSearch) {
          const response = await findAllTerminals({
            filter: [
              {
                value: debouncedSearch,
                rule: FilterRule.LIKE,
                property: 'device_id',
              },
            ],
          });
          data = response.data;
        } else {
          const response = await findAllTerminals({});
          data = response.data;
        }
        setOptionsDeviceIds(
          data.map((item, index) => ({
            key: index,
            label: item.deviceId.toString(),
          }))
        );
      } catch (error) {
        console.error(error);
      }
    } catch (error) {
      console.error(error);
    }
  }, [debouncedSearch, findAllTerminals]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <>
      {isLoading && <Spinner primary />}

      <DetailsRow>
        <DetailsColumn>
          <Title bold>Assign terminal</Title>
          <SelectField
            label='Terminal Device ID'
            placeholder='Terminal Device ID'
            name='terminalDeviceId'
            options={optionsDeviceIds}
            search={searchDeviceId}
            onSearch={setSearchDeviceId}
            short
            stacked
            data-testid={'terminal-input-field'}
          />

          <TextField
            label='Terminal name'
            placeholder='Terminal name'
            type='text'
            name='name'
            short
            stacked
          />
          <SelectField
            label='Zone'
            placeholder='Zone'
            name='zone'
            options={optionsLots}
            search={searchLots}
            onSearch={setSearchLots}
            short
            stacked
          />
          <Title bold>Options</Title>
          <TextField
            label='Electronic Device ID'
            placeholder='Electronic Device ID'
            type='text'
            name='electronicDeviceId'
            short
            stacked
          />
        </DetailsColumn>
      </DetailsRow>

      <Line />
      <Control>
        <Button
          onClick={dirty ? openGlobalCancelModal : closeParentModal}
          size={ButtonSize.LARGE}
          type='button'
          disabled={isSubmitting}
        >
          Cancel
        </Button>
        <Button
          isLoading={isSubmitting}
          size={ButtonSize.LARGE}
          type='submit'
          primary
          disabled={isSubmitting}
        >
          Save
        </Button>
      </Control>
    </>
  );
}
