import type AccountUpdateRequest from '../models/AccountUpdateRequest';
import type AccountUpdateValues from '../models/AccountUpdateValues';

export default function toAccountUpdateRequest(
  data: AccountUpdateValues
): AccountUpdateRequest {
  const {
    contactName,
    contactEmail,
    contactPhone,
    name,
    billingTitle,
    vat,
    address,
    city,
    zipCode,
    country,
    accountRole,
    invoiceCountry,
    invoiceCurrency,
    organisationId,
    customPercentage,
    myPosSecret,
    myPosClientId,
  } = data;
  return {
    name,
    contact_name: contactName,
    contact_email: contactEmail,
    contact_phone:
      contactPhone && contactPhone !== ''
        ? contactPhone.replace(/\s/g, '')
        : contactPhone === null
        ? null
        : undefined,
    billing_title: billingTitle,
    zip_code: zipCode,
    vat,
    address,
    city,
    country_id: country?.key,
    account_role_id: accountRole?.key,
    invoice_country_id: invoiceCountry?.key,
    invoice_currency_id: invoiceCurrency?.key,
    organisation_id: organisationId || undefined,
    custom_percentage: customPercentage || undefined,
    my_pos_client_id: myPosClientId || undefined,
    my_pos_secret: myPosSecret || undefined,
  };
}
