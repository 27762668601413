import { Field, useField } from 'formik';
import React from 'react';
import Color from 'src/shared/constants/Color';
import styled from 'styled-components';

interface CustomCheckboxButtonProps {
  label: string;
  value: string;
}

const CustomCheckboxButton: React.FC<CustomCheckboxButtonProps> = ({
  label,
  value,
}) => {
  const [field] = useField({
    name: 'daysOfWeek',
    type: 'checkbox',
    value,
  });

  return (
    <StyledCheckboxContainer isSelected={field.checked}>
      <HiddenCheckbox type='checkbox' {...field} />
      {label}
    </StyledCheckboxContainer>
  );
};

export default CustomCheckboxButton;

interface StyledCheckboxProps {
  isSelected?: boolean;
}

const StyledCheckboxContainer = styled.label<StyledCheckboxProps>`
  padding: 10px 16px;
  margin-inline: 5px;
  border: 1px solid
    ${({ isSelected }) =>
      isSelected ? Color.PRIMARY_BRAND : Color.DETECTION_BACKGROUND_UNKNOWN};
  background-color: ${({ isSelected }) =>
    isSelected ? Color.PRIMARY_BRAND : Color.BACKGROUND_LIGHTEST};
  color: ${({ isSelected }) =>
    isSelected ? Color.BACKGROUND_LIGHTEST : Color.PRIMARY_BRAND};
  font-size: 14px;
  font-weight: bold;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    background-color: ${({ isSelected }) =>
      isSelected ? Color.PRIMARY_HOVER : Color.BACKGROUND};
  }
`;

const HiddenCheckbox = styled(Field)`
  display: none;
`;
