import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import AnprSharedAccessTable from 'src/anpr/components/AnprSharedAccessTable';
import LotWhitelistLogs from 'src/anpr/components/LotWhitelistLogs';
import RemoteControlLogs from 'src/anpr/components/RemoteControlLogs';
import AnprImageStorageList from 'src/anpr/screens/AnprImageStorageList';
import AnprList from 'src/anpr/screens/AnprList';
import useLprCamerasService from 'src/anpr/services/useLprCamerasService';

import Detection from 'src/detection/screens/Detection';
import type LotDetectionChangeEventData from 'src/detection/socketEvents/LotDetectionChangeEventData';
import InvitationKeysTable from 'src/keysharing/components/InvitationKeysTable';
import KeyLogsTable from 'src/keysharing/components/KeyLogsTable';
import QRCodesTable from 'src/keysharing/components/QRCodesTable';
import WeblinksTable from 'src/keysharing/components/WeblinksTable';
import useLotsContext from 'src/lots/hooks/useLotsContext';
import ZoneList from 'src/lots/screens/ZoneList';
import { ParkingDetectionImplementationStatus } from 'src/products/constants/ParkingDetectionImplementationStatus';
import AdditionalNavigation from 'src/shared/components/AdditionalNavigation';
import AdditionalNavigationLinkContainer from 'src/shared/components/AdditionalNavigation/AdditionalNavigationLinkContainer';
import Dropdown from 'src/shared/components/Dropdown';
import AppRole from 'src/shared/constants/AppRole';
import useUserHasRole from 'src/shared/hooks/useUserHasRole';
import styled from 'styled-components';
import ManagerList from '../../managers/screens/ManagerList';
import ProductList from '../../products/screens/ProductList';
import Breadcrumb from '../../shared/components/Breadcrumb';
import Content from '../../shared/components/Content';
import Main from '../../shared/components/Main';
import useIsMounted from '../../shared/hooks/useIsMounted';
import LotInfo from '../screens/LotInfo';
import SessionsList from '../screens/SessionList/SessionsList';
import useLotService from '../services/useLotService';

const visibleItems = 6;

export default function Lots() {
  const { path, params } = useRouteMatch<{ lotId?: string }>();
  const { lotId } = params;
  const { findOneLot } = useLotService();
  const { listAllLprCameras } = useLprCamerasService();
  const isMounted = useIsMounted();
  const userHasRole = useUserHasRole();
  const [hasCamera, setHasCamera] = useState(false);
  const sharedRef = useRef<React.ElementRef<'div'>>(null);
  const lotRef = useRef<React.ElementRef<'div'>>(null);
  const { lotData, setLotData, setIsLoading } = useLotsContext();

  const id = lotId ? parseInt(lotId) : undefined;

  const [isSharedDropdownOpen, setIsSharedDropdownOpen] = useState(false);
  const [isLotDropdownOpen, setIsLotDropdownOpen] = useState(false);

  const closeSharedDropdown = useCallback(() => {
    setIsSharedDropdownOpen(false);
  }, []);

  const closeLotDropdown = useCallback(() => {
    setIsLotDropdownOpen(false);
  }, []);

  const openSharedDropdown = useCallback(() => {
    setIsSharedDropdownOpen(true);
  }, []);

  const openLotDropdown = useCallback(() => {
    setIsLotDropdownOpen(true);
  }, []);

  useEffect(() => {
    const listener = (event: MouseEvent | FocusEvent) => {
      if (
        !sharedRef?.current?.contains(event.target as Node) ||
        (sharedRef?.current?.contains(event.target as Node) &&
          isSharedDropdownOpen)
      ) {
        closeSharedDropdown();
      }
    };
    document.addEventListener('click', listener, { capture: true });

    return () => {
      document.removeEventListener('click', listener, { capture: true });
    };
  }, [closeSharedDropdown, isSharedDropdownOpen]);

  useEffect(() => {
    const listener = (event: MouseEvent | FocusEvent) => {
      if (
        !lotRef?.current?.contains(event.target as Node) ||
        (lotRef?.current?.contains(event.target as Node) && isLotDropdownOpen)
      ) {
        closeLotDropdown();
      }
    };
    document.addEventListener('click', listener, { capture: true });

    return () => {
      document.removeEventListener('click', listener, { capture: true });
    };
  }, [closeLotDropdown, isLotDropdownOpen]);

  const updateLotOccupancyCount = useCallback(
    (occupiedCount: number, spotCount?: number) => {
      setLotData((oldValue) => {
        if (!oldValue) return undefined;
        return {
          ...oldValue,
          occupiedCount: occupiedCount,
          spotCount: spotCount || oldValue.spotCount,
        };
      });
    },
    [setLotData]
  );

  useEffect(() => {
    const getData = async () => {
      if (!id) {
        return;
      }
      if (isMounted()) {
        setIsLoading(true);
      }
      const lot = findOneLot(id);
      const cameras = listAllLprCameras({ lotId: id });

      const response = await Promise.all([lot, cameras]);

      const [lotData, cameraData] = response;
      if (isMounted()) {
        if (cameraData.data.length > 0) {
          setHasCamera(true);
        } else {
          setHasCamera(false);
        }
        setLotData(lotData);
        setIsLoading(false);
      }
    };
    getData();
  }, [findOneLot, isMounted, id, listAllLprCameras, setLotData, setIsLoading]);

  const userIsParklioInternal = useMemo(() => {
    const roles =
      AppRole.PARKLIO_INTERNAL_SUPER_ADMIN | AppRole.PARKLIO_INTERNAL_ADMIN;

    return userHasRole(roles);
  }, [userHasRole]);

  const userIsPmsAdminOrSuperadmin = useMemo(() => {
    const roles = AppRole.PMS_SUPER_ADMIN | AppRole.PMS_ADMIN;

    return userHasRole(roles);
  }, [userHasRole]);

  const onLotNameChange = useCallback(
    (name: string) => {
      if (name === '') return;
      setLotData((oldValue) =>
        oldValue === undefined
          ? undefined
          : {
              ...oldValue,
              name,
            }
      );
    },
    [setLotData]
  );

  const onLotDetectionEvent = useCallback(
    (lotEvent: LotDetectionChangeEventData[]) => {
      const event = lotEvent.find((event) => event.id === id);
      if (!event) return;

      const { occupiedCount, detectionStatus } = event;

      setLotData((oldValue) =>
        oldValue === undefined
          ? undefined
          : {
              ...oldValue,
              occupiedCount,
              detectionStatus,
              updatedAt: new Date().toLocaleString(),
            }
      );
    },
    [id, setLotData]
  );

  const userCanSeeParkingPlacesTab = useMemo(() => {
    if (!lotData?.detectionImplementationStatus) return undefined;
    const status =
      lotData.detectionImplementationStatus !==
      ParkingDetectionImplementationStatus.NOT_IMPLEMENTED;
    return status || userIsParklioInternal;
  }, [lotData, userIsParklioInternal]);

  const addIcon = useMemo(() => {
    if (!lotData?.detectionImplementationStatus) return undefined;
    const status =
      lotData.detectionImplementationStatus ===
        ParkingDetectionImplementationStatus.PENDING ||
      lotData.detectionImplementationStatus ===
        ParkingDetectionImplementationStatus.BLOCKED;
    return status;
  }, [lotData]);
  return (
    <>
      <AdditionalNavigation>
        <Switch>
          <Route
            exact
            path={`/parking/lots/${lotId}/zones/:masterTerminalId/sessions`}
          >
            <Breadcrumb left to='/parking/lots'>
              <Breadcrumb.Link to={`/parking/lots/${lotId}/products`}>
                {lotData?.name}
              </Breadcrumb.Link>
              <Breadcrumb.Link to={`/parking/lots/${lotId}/zones`} last>
                Zones
              </Breadcrumb.Link>
            </Breadcrumb>
          </Route>
          <Breadcrumb left to='/parking/lots'>
            <Breadcrumb.Link to={`/parking/lots/${lotId}/products`} last>
              {lotData?.name}
            </Breadcrumb.Link>
          </Breadcrumb>
        </Switch>
        <AdditionalNavigationLinkContainer visibleItems={visibleItems}>
          <AdditionalNavigation.Link to={`/parking/lots/${lotId}/products`}>
            Products
          </AdditionalNavigation.Link>
          <AdditionalNavigation.Link to={`/parking/lots/${lotId}/zones`}>
            Zones
          </AdditionalNavigation.Link>
          {(hasCamera || userIsParklioInternal) && (
            <AdditionalNavigation.Link to={`/parking/lots/${lotId}/anpr`}>
              ANPR
            </AdditionalNavigation.Link>
          )}

          {userCanSeeParkingPlacesTab && (
            <AdditionalNavigation.Link
              to={`/parking/lots/${lotId}/places`}
              addIcon={addIcon}
            >
              Parking Places
            </AdditionalNavigation.Link>
          )}
          <StyledDiv ref={sharedRef}>
            <AdditionalNavigation.Link
              to={`/parking/lots/${lotId}/shared`}
              addpadding='true'
            >
              Lot Access
            </AdditionalNavigation.Link>
            <StyledIcon
              className='pa-chevron-down'
              onClick={openSharedDropdown}
            />
            {isSharedDropdownOpen && (
              <Dropdown
                close={closeSharedDropdown}
                navigationView
                hideTabOn='700px'
              >
                <AdditionalNavigation.Link
                  to={`/parking/lots/${lotId}/shared`}
                  exact
                >
                  Shared Keys
                </AdditionalNavigation.Link>
                <AdditionalNavigation.Link
                  to={`/parking/lots/${lotId}/shared/pending`}
                >
                  Pending Keys
                </AdditionalNavigation.Link>
                <AdditionalNavigation.Link
                  to={`/parking/lots/${lotId}/shared/weblinks`}
                >
                  Weblinks
                </AdditionalNavigation.Link>
                <AdditionalNavigation.Link
                  to={`/parking/lots/${lotId}/shared/qr-codes`}
                >
                  QR Codes
                </AdditionalNavigation.Link>
                {(hasCamera || userIsParklioInternal) && (
                  <AdditionalNavigation.Link
                    to={`/parking/lots/${lotId}/shared/anpr`}
                  >
                    License Plates
                  </AdditionalNavigation.Link>
                )}
              </Dropdown>
            )}
          </StyledDiv>
          <StyledDiv ref={lotRef}>
            <AdditionalNavigation.Link
              to={`/parking/lots/${lotId}/lot`}
              addpadding='true'
            >
              Lot Management
            </AdditionalNavigation.Link>
            <StyledIcon className='pa-chevron-down' onClick={openLotDropdown} />
            {isLotDropdownOpen && (
              <Dropdown
                close={closeLotDropdown}
                navigationView
                hideTabOn='800px'
              >
                <AdditionalNavigation.Link
                  to={`/parking/lots/${lotId}/lot`}
                  exact
                >
                  Info
                </AdditionalNavigation.Link>
                <AdditionalNavigation.Link
                  to={`/parking/lots/${lotId}/lot/managers`}
                >
                  Parking Managers
                </AdditionalNavigation.Link>
                {/* <AdditionalNavigation.Link
                  to={`/parking/lots/${lotId}/lot/zones`}
                >
                  Zones
                </AdditionalNavigation.Link> */}
                {((hasCamera && userIsPmsAdminOrSuperadmin) ||
                  userIsParklioInternal) && (
                  <AdditionalNavigation.Link
                    to={`/parking/lots/${lotId}/lot/anpr-subscription`}
                  >
                    ANPR Image Storage
                  </AdditionalNavigation.Link>
                )}
              </Dropdown>
            )}
          </StyledDiv>
        </AdditionalNavigationLinkContainer>
      </AdditionalNavigation>
      <Main left>
        <Content>
          <Switch>
            <Route exact path={`${path}/products`}>
              <ProductList />
            </Route>
            <Route exact path={`${path}/zones`}>
              <ZoneList lotId={id} />
            </Route>
            {(hasCamera || userIsParklioInternal) && (
              <Route exact path={`${path}/anpr`}>
                {lotData && (
                  <AnprList
                    updateLotOccupancyCount={updateLotOccupancyCount}
                    occupiedCount={lotData.occupiedCount}
                    spotCount={lotData.spotCount}
                  />
                )}
              </Route>
            )}
            {(hasCamera || userIsParklioInternal) && (
              <Route exact path={`${path}/anpr/remote-control-logs`}>
                <RemoteControlLogs />
              </Route>
            )}
            {userCanSeeParkingPlacesTab && (
              <Route exact path={`${path}/places`}>
                <Detection
                  onLotDetectionEvent={onLotDetectionEvent}
                  lot={lotData}
                />
              </Route>
            )}
            <Route exact path={`${path}/shared`}>
              <KeyLogsTable />
            </Route>
            <Route exact path={`${path}/shared/pending`}>
              <InvitationKeysTable />
            </Route>
            <Route exact path={`${path}/shared/weblinks`}>
              <WeblinksTable />
            </Route>
            <Route exact path={`${path}/shared/qr-codes`}>
              <QRCodesTable />
            </Route>
            {(hasCamera || userIsParklioInternal) && (
              <Route exact path={`${path}/shared/anpr`}>
                <AnprSharedAccessTable />
              </Route>
            )}
            {(hasCamera || userIsParklioInternal) && (
              <Route exact path={`${path}/shared/anpr/lot-whitelist-logs`}>
                <LotWhitelistLogs />
              </Route>
            )}
            <Route exact path={`${path}/lot`}>
              <LotInfo onLotNameChange={onLotNameChange} />
            </Route>
            <Route exact path={`${path}/lot/managers`}>
              <ManagerList />
            </Route>
            <Route exact path={`${path}/lot/zones`}>
              <ZoneList lotId={id} />
            </Route>
            <Route exact path={`${path}/zones/:masterTerminalId/sessions`}>
              <SessionsList />
            </Route>
            {((hasCamera && userIsPmsAdminOrSuperadmin) ||
              userIsParklioInternal) && (
              <Route exact path={`${path}/lot/anpr-subscription`}>
                <AnprImageStorageList id={id} />
              </Route>
            )}
            <Route>
              <h3>404 PAGE</h3>
            </Route>
          </Switch>
        </Content>
      </Main>
    </>
  );
}

const StyledIcon = styled.i`
  display: flex;
  position: absolute;
  right: 0;
  justify-content: flex-end;
  align-items: center;
  align-self: center;
  width: 100%;
  height: 40px;
  margin-right: 10px;
  cursor: pointer;
  margin-top: 10px;
`;

const StyledDiv = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;
