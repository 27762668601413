import { useCallback } from 'react';
import toMeta from '../../shared/mappers/toMeta';
import type GetAllDetails from '../../shared/models/GetAllDetails';
import useApiService from '../../shared/services/useApiService';
import toSession from '../mappers/ToSession';
import { toSessionQuery } from '../mappers/toSessionQuery';
import ToTariff from '../mappers/ToTariff';
import { toTariffCreateParams } from '../mappers/toTariffCreateParams';
import { toTerminal } from '../mappers/toTerminal';
import { toTerminalCreateRequest } from '../mappers/toTerminalCreateParams';
import { toTerminalQuery } from '../mappers/toTerminalQuery';
import { toTerminalUpdateParams } from '../mappers/toTerminalUpdateParams';
import type Session from '../models/Session';
import type SessionQuery from '../models/SessionQuery';
import type SessionQueryValues from '../models/SessionQueryValues';
import type SessionResponse from '../models/SessionResponse';
import type Tariff from '../models/Tariff';
import type TariffCreateRequest from '../models/TariffCreateParams';
import type TariffCreateResponse from '../models/TariffCreateResponse';
import type TariffFormValues from '../models/TariffFormValues';
import type TariffQuery from '../models/TariffQuery';
import type TariffQueryValues from '../models/TariffQueryValues';
import type TariffResponse from '../models/TariffResponse';
import type TerminalsQuery from '../models/TermianlsQuery';
import type TermianlQueryValues from '../models/TermianlsQueryValues';
import type Terminal from '../models/Terminal';
import type TerminalCreateRequest from '../models/TerminalCreateParams';
import type TerminalCreateReponse from '../models/TerminalCreateReponse';
import type TerminalFormValues from '../models/TerminalFormValues';
import type TerminalOperateRequest from '../models/TerminalOperateParams';
import type TerminalOperateResponse from '../models/TerminalOperateResponse';
import type TerminalRebootRequest from '../models/TerminalRebootRequest';
import type TerminalRebootResponse from '../models/TerminalRebootResponse';
import type TerminalRemoveResponse from '../models/TerminalRemoveResponse';
import type TerminalResponse from '../models/TerminalResponse';
import type TerminalUpdateFormValues from '../models/TerminalUpdateFormValues';
import type TerminalUpdateParams from '../models/TerminalUpdateParams';

const terminalsBaseURL = '/terminals';
interface TerminalService {
  createTerminal: (
    values: TerminalFormValues,
    targetAccountId?: number
  ) => Promise<TerminalCreateReponse>;
  findOneTerminal: (id: number) => Promise<Terminal>;
  findAllTerminals: (
    values: TermianlQueryValues
  ) => Promise<GetAllDetails<Terminal>>;
  operateTerminal: (id: number) => Promise<Terminal>;
  removeTerminal: (id: number) => Promise<TerminalRemoveResponse>;
  updateTerminal: (
    id: number,
    values: TerminalUpdateFormValues
  ) => Promise<Terminal>;
  rebootTerminal: (id: number) => Promise<TerminalRemoveResponse>;
  getSessions: (values: SessionQueryValues) => Promise<GetAllDetails<Session>>;
  createTariff: (
    values: TariffFormValues,
    terminalId: number
  ) => Promise<TariffCreateResponse>;
  getTariffs: (values: TariffQueryValues) => Promise<GetAllDetails<Tariff>>;
  updateTariff: (
    id: number,
    values: TariffFormValues
  ) => Promise<TariffResponse>;
}

export default function useTerminalsService(): TerminalService {
  const { get, post, remove, patch } = useApiService();

  const findOneTerminal = useCallback(
    async (id: number) => {
      const response = await get<TerminalResponse>(`${terminalsBaseURL}/${id}`);

      const data = toTerminal(response.data);

      return data;
    },
    [get]
  );

  const findAllTerminals = useCallback(
    async (values: TermianlQueryValues) => {
      const query = toTerminalQuery(values);
      const response = await get<TerminalResponse[], TerminalsQuery>(
        `${terminalsBaseURL}`,
        query
      );

      const data = response.data.map(toTerminal);
      const meta = toMeta(response.meta);

      return { data, meta };
    },
    [get]
  );

  const createTerminal = useCallback(
    async (values: TerminalFormValues) => {
      const requestBody = toTerminalCreateRequest(values);
      const response = await post<TerminalCreateRequest, TerminalCreateReponse>(
        `${terminalsBaseURL}`,
        requestBody
      );

      return response;
    },
    [post]
  );

  const removeTerminal = useCallback(
    async (id: number) => {
      const response = await remove<TerminalRemoveResponse>(
        `${terminalsBaseURL}/${id}`
      );

      return response;
    },
    [remove]
  );

  const updateTerminal = useCallback(
    async (id: number, values: TerminalUpdateFormValues) => {
      const params = toTerminalUpdateParams(values);
      const response = await patch<TerminalUpdateParams, TerminalResponse>(
        `${terminalsBaseURL}/${id}`,
        params
      );
      return toTerminal(response.data);
    },
    [patch]
  );

  const rebootTerminal = useCallback(
    async (id: number) => {
      const response = await post<
        TerminalRebootRequest,
        TerminalRebootResponse
      >(`${terminalsBaseURL}/${id}/reboot`, {});
      return response;
    },
    [post]
  );

  const getSessions = useCallback(
    async (values: SessionQueryValues) => {
      const query = toSessionQuery(values);
      const response = await get<SessionResponse[], SessionQuery>(
        `${terminalsBaseURL}/${values.terminalId}/sessions`,
        query
      );
      const data = response.data.map(toSession);
      const meta = toMeta(response.meta);
      return { data, meta };
    },
    [get]
  );

  const getTariffs = useCallback(
    async (values: TariffQueryValues) => {
      const query = toSessionQuery(values);
      const response = await get<TariffResponse[], TariffQuery>(
        `${terminalsBaseURL}/${values.terminalId}/tariffs`,
        query
      );
      const data = response.data.map(ToTariff);
      const meta = toMeta(response.meta);
      return { data, meta };
    },
    [get]
  );

  const createTariff = useCallback(
    async (values: TariffFormValues, terminalId: number) => {
      const requestBody = toTariffCreateParams(values);
      const response = await post<TariffCreateRequest, TariffCreateResponse>(
        `${terminalsBaseURL}/${terminalId}/tariffs`,
        requestBody
      );

      return response;
    },
    [post]
  );

  const updateTariff = useCallback(
    async (id: number, values: TariffFormValues) => {
      const params = toTariffCreateParams(values);
      const response = await patch<TariffCreateRequest, TariffResponse>(
        `${terminalsBaseURL}/${id}/tariffs`,
        params
      );
      return response.data;
    },
    [patch]
  );

  const operateTerminal = useCallback(
    async (id: number) => {
      const response = await post<
        TerminalOperateRequest,
        TerminalOperateResponse
      >(`${terminalsBaseURL}/${id}/operate`, {
        operation: 'open',
        access_point_id: 1,
      });

      const data = toTerminal(response.data);

      return data;
    },
    [post]
  );

  return {
    findAllTerminals,
    findOneTerminal,
    createTerminal,
    removeTerminal,
    operateTerminal,
    updateTerminal,
    rebootTerminal,
    getSessions,
    getTariffs,
    createTariff,
    updateTariff,
  };
}
