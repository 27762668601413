import React, { useCallback, useEffect, useState } from 'react';
import type LprCamera from 'src/anpr/models/LprCamera/LprCamera';
import useLprCamerasService from 'src/anpr/services/useLprCamerasService';
import Content from 'src/shared/components/Content';
import Main from 'src/shared/components/Main';
import Spinner from 'src/shared/components/Spinner';
import StyledNoData from 'src/shared/components/StyledNoData';
import Title from 'src/shared/components/Title';
import useIsMounted from '../../shared/hooks/useIsMounted';
import AnprCameraParklioInternalTable from '../components/AnprCameraParklioInternalTable';

export interface AnprCameraParklioInternalListProps {
  accountId: number;
}

export default function AnprCameraParklioInternalList(
  props: AnprCameraParklioInternalListProps
) {
  const { accountId } = props;
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [camera, setCameraData] = useState<LprCamera[]>([]);
  const { listAllLprCameras, updateCamera } = useLprCamerasService();
  const isMounted = useIsMounted();

  const getData = useCallback(async () => {
    try {
      if (isMounted()) {
        setIsDataLoading(true);
      }

      const response = await listAllLprCameras({ accountId });

      if (isMounted()) {
        setCameraData(response.data);
        setIsDataLoading(false);
      }
    } catch (error) {
      if (isMounted()) {
        setIsDataLoading(false);
      }
      throw error;
    }
  }, [isMounted, listAllLprCameras, accountId]);

  useEffect(() => {
    getData();
  }, [getData]);

  const onChangeState = useCallback(
    async (id: number) => {
      const cameraIndex = camera.findIndex((data) => data.id === id);
      if (cameraIndex === -1) return;
      if (isMounted()) {
        setIsDataLoading(true);
      }
      try {
        if (isMounted()) {
          await updateCamera(id, {
            saveImage: !camera[cameraIndex].saveImage,
            name: camera[cameraIndex].name,
          });
          setCameraData((oldData) => {
            const cameraIndex = oldData.findIndex((data) => data.id === id);
            if (cameraIndex === -1) return oldData;
            oldData[cameraIndex].saveImage = !oldData[cameraIndex].saveImage;
            return [...oldData];
          });
          setIsDataLoading(false);
        }
      } catch (error) {
        if (isMounted()) {
          setIsDataLoading(false);
        }
        throw error;
      }
    },
    [camera, isMounted, updateCamera]
  );

  return (
    <>
      <Title anpr>ANPR Image Storage</Title>
      <Main>
        <Content>
          {isDataLoading ? (
            <Spinner primary anpr />
          ) : camera.length > 0 ? (
            <AnprCameraParklioInternalTable
              data={camera}
              onChangeState={onChangeState}
              getData={getData}
            />
          ) : (
            <StyledNoData>No cameras added.</StyledNoData>
          )}
        </Content>
      </Main>
    </>
  );
}
