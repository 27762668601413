import type Tariff from '../models/Tariff';
import type TariffResponse from '../models/TariffResponse';

function ToTariff(props: TariffResponse): Tariff {
  const { id, name, starts_at, ends_at, price } = props;
  return {
    id,
    name,
    startsAt: starts_at,
    endsAt: ends_at,
    price,
  };
}
export default ToTariff;
