import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Color from 'src/shared/constants/Color';
import styled from 'styled-components';

interface StyledNavLinkProps {
  disabled: boolean;
  linkTo: string;
  tooltipMessage: string;
  linkText: string;
}

export default function StyledNavLink({
  disabled,
  linkTo,
  tooltipMessage,
  linkText,
}: StyledNavLinkProps) {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <StyledLink
      to={linkTo}
      onClick={(e) => disabled && e.preventDefault()}
      onMouseEnter={() => disabled && setShowTooltip(true)}
      onMouseLeave={() => disabled && setShowTooltip(false)}
      $isDisabled={disabled}
    >
      <p>{linkText}</p>
      {showTooltip && disabled && <HoverDiv>{tooltipMessage}</HoverDiv>}
    </StyledLink>
  );
}

const HoverDiv = styled.div`
  position: absolute;
  top: 70px;
  right: 0px;
  background: ${Color.BACKGROUND_HOVER};
  color: ${Color.SHADOW_DARKEST_TRANSPARENT};
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 10;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
`;

interface StyledLinkProps {
  $isDisabled?: boolean;
}

const StyledLink = styled(NavLink)<StyledLinkProps>`
  text-decoration: none;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 170%;
  display: flex;
  align-self: flex-end;
  color: ${({ $isDisabled }) =>
    $isDisabled ? Color.TEXT_LIGHT : Color.TEXT_BRAND};
  cursor: ${({ $isDisabled }) => ($isDisabled ? 'not-allowed' : 'pointer')};
`;
