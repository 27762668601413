import type TerminalCreateRequest from '../models/TerminalCreateParams';
import type TerminalFormValues from '../models/TerminalFormValues';

export function toTerminalCreateRequest(
  data: TerminalFormValues
): TerminalCreateRequest {
  const { deviceId, publicKey, type, isMaster, mac } = data;

  return {
    device_id: deviceId,
    mac,
    public_key: publicKey,
    type,
    is_master: isMaster,
  };
}
