import { useField } from 'formik';
import React from 'react';
import Error from 'src/shared/components/Error';
import styled from 'styled-components';
import Label from '../Label';
import CustomCheckboxButton from '../StyledRadioButton/StyledCheckbox';

export interface StyledCheckboxOptions {
  label: string;
  value: string;
}

export interface StyledCheckboxRowProps {
  option: StyledCheckboxOptions[];
  addBlockMaring?: boolean;
  title?: string;
}

export default function StyledCheckboxRow(props: StyledCheckboxRowProps) {
  const { option, title } = props;

  const [, meta] = useField({
    name: 'daysOfWeek',
    type: 'checkbox',
  });

  const { error, touched } = meta;

  const shouldShowError = error && touched;
  return (
    <StyledWrapper>
      <Label>{title}</Label>
      <StyledRowContainer>
        {option.map((day) => (
          <CustomCheckboxButton
            key={day.value}
            label={day.label}
            value={day.value}
          />
        ))}
      </StyledRowContainer>
      {shouldShowError && <Error>*{error}</Error>}
    </StyledWrapper>
  );
}

const StyledRowContainer = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-block: 50px;
`;
