import type LotWhitelistCsvExportQuery from '../models/LotWhitelist/LotWhitelistCsvExportQuery';
import type LotWhitelistCsvExportQueryValues from '../models/LotWhitelist/LotWhitelistCsvExportQueryValues';

export default function toLotWhitelistExportCsvQuery(
  data: LotWhitelistCsvExportQueryValues
): LotWhitelistCsvExportQuery {
  const { filter } = data;

  return {
    filter: filter
      ? `${filter[0].property}:${filter[0].rule}:${filter[0].value}`
      : undefined,
  };
}
