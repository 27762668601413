import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Card from 'src/shared/components/Card';
import Content from 'src/shared/components/Content';
import Input from 'src/shared/components/Input';
import Pagination from 'src/shared/components/Pagination';
import StyledNoData from 'src/shared/components/StyledNoData';
import Title from 'src/shared/components/Title';
import PaginationSize from 'src/shared/constants/DataSize';
import InitialMetaData from 'src/shared/constants/InitialMetaData';
import PaginationItemDisplay from 'src/shared/constants/PaginationItemDisplay';
import SearchTimer from 'src/shared/constants/SearchTimer';
import useIsMounted from 'src/shared/hooks/useIsMounted';
import { FilterRule } from 'src/shared/models/FilterRule';
import type Meta from 'src/shared/models/Meta';
import debounce from 'src/shared/utils/debounce';
import removeWhitespaceAndHyphens from 'src/shared/utils/removeWhitespaceAndHyphens';
import SessionTable from '../../components/SessionTable/SessionTable';
import type Session from '../../models/Session';
import useTerminalsService from '../../services/useTerminalService';

export interface SessionListRouteParams {
  masterTerminalId: string;
}
export default function SessionsList() {
  const isMounted = useIsMounted();
  const { masterTerminalId } = useParams<SessionListRouteParams>();
  const [isDataLoading, setIsDataLoading] = useState(false);
  const sessionIdRefSearchValue = React.useRef<HTMLInputElement>(null);
  const [sessionIdInputValue, setSessionIdInputValue] = useState('');
  const [sessionIdSearchValue, setSessionIdSearchValue] = useState('');

  const [data, setData] = useState<Session[]>([]);
  const [meta, setMeta] = useState<Meta>(InitialMetaData);

  const { getSessions } = useTerminalsService();

  const onSearchSessionId = useCallback(async () => {
    if (sessionIdRefSearchValue.current === null) {
      return;
    }
    if (sessionIdInputValue !== sessionIdRefSearchValue.current.value) {
      return;
    }

    setSessionIdSearchValue(sessionIdInputValue);
    setIsDataLoading(false);
  }, [sessionIdInputValue]);

  const onChangeSessionId = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSessionIdInputValue(event.target.value);
  };

  const getData = useCallback(
    async (page: number) => {
      if (!masterTerminalId) {
        return;
      }
      if (isMounted()) {
        setIsDataLoading(true);
      }

      try {
        const response = await getSessions({
          page,
          filter: sessionIdSearchValue
            ? [
                {
                  value: removeWhitespaceAndHyphens(sessionIdSearchValue),
                  rule: FilterRule.LIKE,
                  property: 'session_id',
                },
              ]
            : undefined,
          size: PaginationSize.STANDARD,
          terminalId: parseInt(masterTerminalId),
        });
        const data = response.data;
        const meta = response.meta;
        setData(data);

        if (meta !== undefined) {
          setMeta(meta);
        }
        setIsDataLoading(false);
      } catch (error) {
        if (isMounted()) {
          setIsDataLoading(false);
        }
        throw error;
      }
    },
    [getSessions, isMounted, masterTerminalId, sessionIdSearchValue]
  );

  useEffect(() => {
    getData(1);
  }, [getData]);

  useEffect(() => {
    setIsDataLoading(true);
    const search = debounce(onSearchSessionId, SearchTimer.INPUT_SEARCH);
    search();
  }, [onSearchSessionId]);

  return (
    <Content widthSize='100%'>
      <Title>List of sessions</Title>
      <Card>
        <>
          <Input
            placeholder='Search by session id'
            type='text'
            ref={sessionIdRefSearchValue}
            value={sessionIdInputValue}
            onChange={onChangeSessionId}
            data-testid='filter-input'
          />
          {data.length < 1 ? (
            <StyledNoData>No sessions</StyledNoData>
          ) : (
            <SessionTable sessions={data} isLoading={isDataLoading} />
          )}
        </>
      </Card>

      {meta.total >= PaginationItemDisplay.DISPLAYED_ITEMS && (
        <Pagination meta={meta} getData={getData} />
      )}
    </Content>
  );
}
