import React, { useCallback, useState } from 'react';
import { GetLicensePurchaseDescription } from 'src/anpr/components/GetLicenseDescription';
import type AccountProductLicenses from 'src/product-license/models/AccountProductLicenses';
import type ProductLicenses from 'src/product-license/models/ProductLicenses';
import Button from 'src/shared/components/Button';
import ButtonsContainer from 'src/shared/components/ButtonsContainer';
import Card from 'src/shared/components/Card';
import Line from 'src/shared/components/Line';
import StyledBlockIcon from 'src/shared/components/StyledBlockIcon';
import Table from 'src/shared/components/Table';
import Title from 'src/shared/components/Title';
import ToggleSwitch from 'src/shared/components/ToggleSwitch';
import ActionIcon from 'src/shared/constants/ActionIcon';
import ButtonSize from 'src/shared/constants/ButtonSize';
import styled from 'styled-components';

interface LicenseInfoTableProps {
  licenseData: ProductLicenses[];
  totalLicenses: AccountProductLicenses | undefined;
  openAddLicenseModal: () => void;
  isLoading?: boolean;
  onToggleChange: (id: number, active: boolean) => void;
  onRemove: (id: number) => void;
}

export default function LicenseInfoTable(props: LicenseInfoTableProps) {
  const {
    licenseData,
    totalLicenses,
    isLoading,
    openAddLicenseModal,
    onToggleChange,
    onRemove,
  } = props;

  const [hideDeleteIcon, setHideDeleteIcon] = useState<{
    [key: number]: boolean;
  }>({});
  const [showConfirmationButtons, setShowConfirmationButtons] = useState<{
    [key: number]: boolean;
  }>({});

  const onDeleteIconClick = useCallback(
    (id: number) => {
      if (!hideDeleteIcon[id]) {
        setHideDeleteIcon({ [id]: true });
        setShowConfirmationButtons({ [id]: true });
      }
    },
    [hideDeleteIcon]
  );

  return (
    <>
      <Wrapper>
        <Title addCustomMargin='40px 0 0 0' endAlignment>
          Licenses
        </Title>
        <Button
          size={ButtonSize.LARGE}
          type='submit'
          primary
          onClick={openAddLicenseModal}
        >
          Add license
        </Button>
      </Wrapper>
      <Card>
        <StyledTotalLicenseCard>
          TOTAL PRODUCTS ON ACCOUNT: <b>{totalLicenses?.barriers || 0}</b>{' '}
          Barriers / <b>{totalLicenses?.bollards || 0}</b> Bollards /{' '}
          <b>{totalLicenses?.brains || 0}</b> Brains /{' '}
          <b>{totalLicenses?.lprCameras || 0}</b> Cameras /{' '}
          <b>{totalLicenses?.chains || 0}</b> Chains /{' '}
          <b>{totalLicenses?.gates || 0}</b> Gates /{' '}
          <b>{totalLicenses?.parkingPlaces || 0}</b> Parking palces
        </StyledTotalLicenseCard>
      </Card>
      <Card>
        <Table isLoading={isLoading}>
          <Table.Head>
            <Table.Row>
              <Table.Header>License</Table.Header>
              <Table.Header>Created On</Table.Header>
              <Table.Header>Created By</Table.Header>
              <Table.Header>Products</Table.Header>
              <Table.Header>Active</Table.Header>
              <Table.Header />
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {licenseData.map((license) => {
              const {
                id,
                active,
                createdAt,
                createdBy,
                barriers,
                bollards,
                brains,
                chains,
                gates,
                lprCameras,
                parkingPlaces,
              } = license;
              const licenseCounts: AccountProductLicenses = {
                gates,
                brains,
                barriers,
                bollards,
                chains,
                lprCameras,
                parkingPlaces,
              };

              const onChange = () => {
                onToggleChange(id, active);
              };

              const onIconClick = () => {
                onDeleteIconClick(id);
              };

              const onRemoveClick = () => {
                onRemove(id);
              };

              const onCancelClick = () => {
                setHideDeleteIcon({
                  [id]: false,
                });
                setShowConfirmationButtons({
                  [id]: false,
                });
              };

              return (
                <Table.Row key={id}>
                  <Table.Cell>{id}</Table.Cell>
                  <Table.Cell>{createdAt.toDateString()}</Table.Cell>
                  <Table.Cell>{createdBy}</Table.Cell>

                  <Table.Cell>
                    <ContainerDiv>
                      {GetLicensePurchaseDescription(licenseCounts)}
                    </ContainerDiv>
                  </Table.Cell>
                  <Table.Cell>
                    <ToggleSwitch
                      state={!!license.active}
                      condition
                      onChange={onChange}
                      name=''
                    />
                  </Table.Cell>
                  <Table.Cell right>
                    {!hideDeleteIcon[id] && (
                      <StyledBlockIcon
                        data-testid='delete-icon'
                        className={ActionIcon.DELETE}
                        onClick={onIconClick}
                      />
                    )}
                    {showConfirmationButtons[id] && (
                      <ButtonsContainer table>
                        <Button
                          size={ButtonSize.SMALL}
                          style={{
                            marginRight: '5px',
                          }}
                          onClick={onRemoveClick}
                          primary
                        >
                          Delete
                        </Button>
                        <Button size={ButtonSize.SMALL} onClick={onCancelClick}>
                          Cancel
                        </Button>
                      </ButtonsContainer>
                    )}
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
        <Line />
      </Card>
    </>
  );
}
const ContainerDiv = styled.div`
  text-wrap: nowrap;
  max-width: 50%;
  flex-wrap: wrap;
  display: flex;
  white-space: break-spaces;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  & > button {
    width: 232px;
  }
`;

const StyledTotalLicenseCard = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  line-height: 19px;
  font-size: 14px;
`;
