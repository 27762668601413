import React from 'react';
import type Session from 'src/lots/models/Session';
import calculateTimeDuration from 'src/lots/utils/calculateTimeDuration';
import Table from 'src/shared/components/Table';
import { formatDateTime } from 'src/shared/utils/formatDateTime';

export interface SessionTableProps {
  sessions: Session[];
  isLoading: boolean;
}

export default function SessionTable(data: SessionTableProps) {
  const { sessions, isLoading } = data;

  return (
    <Table margin isLoading={isLoading}>
      <Table.Head>
        <Table.Row>
          <Table.Header> SESSION ID</Table.Header>
          <Table.Header> ENTRY TIME</Table.Header>
          <Table.Header> EXIT TIME</Table.Header>
          <Table.Header> ENTRY DURATION</Table.Header>
          <Table.Header> PAID TOTAL</Table.Header>
          <Table.Header> PAYMENT TYPE</Table.Header>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {sessions.map((data) => {
          const { sessionId, endedAt, startedAt, amountPaid, payments } = data;

          return (
            <Table.Row key={sessionId}>
              <Table.Cell>ID - {sessionId}</Table.Cell>
              <Table.Cell>{formatDateTime(startedAt)}</Table.Cell>
              <Table.Cell>
                {endedAt ? formatDateTime(endedAt) : 'N/A'}
              </Table.Cell>
              <Table.Cell>
                {calculateTimeDuration(startedAt, endedAt)}
              </Table.Cell>
              <Table.Cell>${amountPaid}</Table.Cell>
              <Table.Cell>{payments[0]?.paymentMethod || `NONE`} </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
}
