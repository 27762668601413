import type SessionQuery from '../models/SessionQuery';
import type SessionQueryValues from '../models/SessionQueryValues';

export function toSessionQuery(data: SessionQueryValues): SessionQuery {
  const { page, size, filter } = data;

  return {
    filter: filter
      ? `${filter[0].property}:${filter[0].rule}:${filter[0].value}`
      : undefined,
    page,
    size,
  };
}
