import React, { useEffect, useMemo, useState } from 'react';
import Button from 'src/shared/components/Button';
import Card from 'src/shared/components/Card';
import Control from 'src/shared/components/Control';
import Line from 'src/shared/components/Line';
import Section from 'src/shared/components/Section';
import SelectField from 'src/shared/components/SelectField/index';
import TextField from 'src/shared/components/TextField';
import Title from 'src/shared/components/Title';
import type Option from 'src/shared/models/Option';
import useAccountRoleService from 'src/shared/services/useAccountRoleService';
import AppRole from '../../shared/constants/AppRole';
import ButtonSize from '../../shared/constants/ButtonSize';
import useIsMounted from '../../shared/hooks/useIsMounted';
import useUserHasRole from '../../shared/hooks/useUserHasRole';

export default function AccountInfoForm() {
  const [options, setOptions] = useState<Option[]>([]);
  const { findAllAccountRoles } = useAccountRoleService();
  const isMounted = useIsMounted();
  const userHasRole = useUserHasRole();

  const userIsParklioInternal = useMemo(() => {
    const roles =
      AppRole.PARKLIO_INTERNAL_SUPER_ADMIN | AppRole.PARKLIO_INTERNAL_ADMIN;

    return userHasRole(roles);
  }, [userHasRole]);

  useEffect(() => {
    const getData = async () => {
      const data = await findAllAccountRoles();
      if (isMounted()) {
        const options = data.map(({ id, name }) => ({
          key: id,
          label: name,
        }));

        setOptions(options);
      }
    };

    getData();
  }, [findAllAccountRoles, isMounted]);

  return (
    <>
      <Title>Account</Title>
      <Card>
        <Section>
          <TextField
            label='Account'
            placeholder='Account'
            type='text'
            short
            name='name'
            readonly={!userIsParklioInternal}
            testId='account-input'
          />
          <SelectField
            label='Account Type'
            placeholder='Select Account Type'
            name='accountRole'
            options={options}
            short
            readonly={!userIsParklioInternal}
          />
        </Section>
      </Card>
      <Title>Space Invoice</Title>
      <Card>
        <Section>
          <TextField
            label='Organisation Id'
            placeholder='Organisation Id'
            name='organisationId'
            type='text'
            testId='organisation-id-input'
            short
            readonly={!userIsParklioInternal}
          />
          <TextField
            label='Custom Percentage'
            placeholder='Custom Percentage'
            name='customPercentage'
            type='text'
            testId='custom-percentage-input'
            short
            readonly={!userIsParklioInternal}
          />
        </Section>
      </Card>
      <Title>MyPos</Title>
      <Card>
        <Section>
          <TextField
            label='API secret'
            placeholder='API secret'
            name='myPosSecret'
            type='text'
            testId='api-secret'
            short
            readonly={!userIsParklioInternal}
          />
          <TextField
            label='Client ID'
            placeholder='Client ID'
            name='myPosClientId'
            type='text'
            testId='client-id'
            short
            readonly={!userIsParklioInternal}
          />
        </Section>
      </Card>
      <Title>Contact Person</Title>
      <Card>
        <Section>
          <TextField
            label='Contact Name'
            placeholder='Contact Name'
            type='text'
            short
            name='contactName'
          />
          <TextField
            label='Contact Email'
            placeholder='Contact Email'
            type='text'
            short
            name='contactEmail'
          />
          <TextField
            label='Contact Phone'
            placeholder='Contact Phone'
            type='text'
            short
            name='contactPhone'
          />
        </Section>
        <Line />
        <Control>
          <Button size={ButtonSize.LARGE} type='submit' primary>
            Save
          </Button>
        </Control>
      </Card>
    </>
  );
}
