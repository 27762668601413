TerminalsParklioInternalList;

import React, { useCallback, useEffect, useState } from 'react';
import AssignTerminalModal from 'src/lots/components/AssignTerminalModal/AssignTerminalModal';
import TerminalsInternalTable from 'src/lots/components/TerminalsInternalTable/TerminalsInternalTable';
import type Terminal from 'src/lots/models/Terminal';
import useTerminalService from 'src/lots/services/useTerminalService';
import Button from 'src/shared/components/Button';
import Content from 'src/shared/components/Content';
import Main from 'src/shared/components/Main';
import GlobalModal from 'src/shared/components/Modals/GlobalModal';
import Pagination from 'src/shared/components/Pagination';
import Spinner from 'src/shared/components/Spinner';
import StyledNoData from 'src/shared/components/StyledNoData';
import Title from 'src/shared/components/Title';
import ButtonSize from 'src/shared/constants/ButtonSize';
import PaginationSize from 'src/shared/constants/DataSize';
import InitialMetaData from 'src/shared/constants/InitialMetaData';
import PaginationItemDisplay from 'src/shared/constants/PaginationItemDisplay';
import { useGlobalModal } from 'src/shared/hooks/useGlobalModal';
import useIsMounted from 'src/shared/hooks/useIsMounted';
import type Meta from 'src/shared/models/Meta';
import styled from 'styled-components';

export interface TerminalsParklioInternalListProps {
  accountId: number;
}

export default function TerminalsParklioInternalList(
  props: TerminalsParklioInternalListProps
) {
  const { accountId } = props;
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [terminals, setTerminals] = useState<Terminal[]>([]);
  const [meta, setMeta] = useState<Meta>(InitialMetaData);

  const { findAllTerminals, removeTerminal } = useTerminalService();
  const isMounted = useIsMounted();

  const getData = useCallback(
    async (page: number) => {
      try {
        if (isMounted()) {
          setIsDataLoading(true);
        }

        const response = await findAllTerminals({
          zoneId: accountId,
          page,
          size: PaginationSize.STANDARD,
        });

        if (isMounted()) {
          setTerminals(response.data);
          if (meta !== undefined) {
            setMeta(meta);
          }
          setIsDataLoading(false);
        }
      } catch (error) {
        if (isMounted()) {
          setIsDataLoading(false);
        }
        throw error;
      }
    },
    [isMounted, findAllTerminals, accountId, meta]
  );

  useEffect(() => {
    getData(1);
  }, [getData]);

  const onTerminalNameChange = useCallback((name: string, id: number) => {
    if (name === '' || id === 0) return;
    setTerminals((oldData) => {
      const zoneIndex = oldData.findIndex((data) => data.id === id);
      if (zoneIndex === -1) return oldData;
      oldData[zoneIndex].name = name;
      return [...oldData];
    });
  }, []);

  const onAssignTerminal = useCallback((terminal: Terminal) => {
    setTerminals((oldData) => {
      const existingIndex = oldData.findIndex((t) => t.id === terminal.id);
      if (existingIndex !== -1) {
        return oldData.map((t, index) =>
          index === existingIndex ? terminal : t
        );
      }
      return [terminal, ...oldData];
    });
  }, []);

  const onRemoveTerminal = useCallback(
    async (id: number) => {
      if (!id) return;
      try {
        if (isMounted()) {
          setIsDataLoading(true);
        }
        await removeTerminal(id);
        setTerminals((prevState) => prevState.filter((item) => item.id !== id));
        setIsDataLoading(false);
      } catch (error) {
        if (isMounted()) {
          setIsDataLoading(false);
        }
        throw error;
      }
    },
    [isMounted, removeTerminal]
  );

  const [openTerminalDetailsModal, closeTerminalDetailsModal] = useGlobalModal(
    () => (
      <GlobalModal isOpen>
        <AssignTerminalModal
          accountId={accountId}
          closeParentModal={closeTerminalDetailsModal}
          onAssignTerminal={onAssignTerminal}
        />
      </GlobalModal>
    )
  );

  return (
    <>
      <StyledWrapper>
        <Title anpr>Terminals</Title>
        <Button
          size={ButtonSize.LARGE}
          primary
          onClick={openTerminalDetailsModal}
        >
          Assign Terminal
        </Button>
      </StyledWrapper>
      <Main>
        <Content>
          {isDataLoading ? (
            <Spinner primary anpr />
          ) : terminals.length > 0 ? (
            <>
              <TerminalsInternalTable
                terminals={terminals}
                isLoading={isDataLoading}
                onEditTerminal={onTerminalNameChange}
                onRemoveTerminal={onRemoveTerminal}
              />
              {meta.total >= PaginationItemDisplay.DISPLAYED_ITEMS && (
                <Pagination meta={meta} getData={getData} />
              )}
            </>
          ) : (
            <StyledNoData>No terminals added.</StyledNoData>
          )}
        </Content>
      </Main>
    </>
  );
}
const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @media (max-width: 699px) {
    flex-direction: column;
  }
`;
