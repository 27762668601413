import type Profile from 'src/settings/models/Profile';
import type AccountInfoFormValues from '../models/AccountInfoFormValues';

export default function toAccountInfoFormValues(
  data: Profile
): AccountInfoFormValues | undefined {
  const { account } = data;

  if (!account) {
    return undefined;
  }

  const {
    name,
    accountRole,
    contactEmail,
    contactName,
    contactPhone,
    customPercentage,
    organisationId,
    myPosSecret,
    myPosClientId,
  } = account;

  return {
    name: name || '',
    accountRole: accountRole
      ? { key: accountRole.id, label: accountRole.name }
      : null,
    contactEmail: contactEmail || '',
    contactName: contactName || '',
    contactPhone: contactPhone || '',
    customPercentage: customPercentage || null,
    organisationId: organisationId || '',
    myPosSecret,
    myPosClientId,
  };
}
