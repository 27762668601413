import TerminalStatus from '../constants/TerminalStatus';
import type { Terminal } from '../models/Terminal';
import type { TerminalResponse } from '../models/TerminalResponse';

export function toTerminal(data: TerminalResponse): Terminal {
  const {
    id,
    zone_id,
    name,
    status,
    device_id,
    ip_address,
    version,
    mac,
    is_master,
    type,
    pos_serial_number,
    electronic_device_id,
    last_seen,
    updated_at,
    created_at,
  } = data;

  return {
    id,
    zoneId: zone_id,
    name,
    status:
      status === TerminalStatus.OFFLINE
        ? TerminalStatus.OFFLINE
        : TerminalStatus.ONLINE,
    deviceId: device_id,
    ipAddress: ip_address,
    version,
    mac,
    isMaster: is_master,
    type,
    posSerialNumber: pos_serial_number,
    electronicDeviceId: electronic_device_id,
    lastSeen: new Date(last_seen),
    updatedAt: new Date(updated_at),
    createdAt: new Date(created_at),
  };
}
