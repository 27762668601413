import React, { useCallback, useMemo, useState } from 'react';
import useLotWhitelistsService from 'src/anpr/services/useLotWhitelistsService';
import DetailsColumn from 'src/products/components/DetailsColumn';
import Button from 'src/shared/components/Button';
import Control from 'src/shared/components/Control';
import Description from 'src/shared/components/Description';
import { useGlobalFailureModal } from 'src/shared/components/Modals/GlobalFailureModal';
import { useGlobalSuccessModal } from 'src/shared/components/Modals/GlobalSuccessModal';
import Title from 'src/shared/components/Title';
import ButtonSize from 'src/shared/constants/ButtonSize';
import Color from 'src/shared/constants/Color';
import useIsMounted from 'src/shared/hooks/useIsMounted';
import styled from 'styled-components';

interface UploadWhitelistTableFormProps {
  closeParentModal: () => void;
  lotId: string;
}

const MAX_SIZE_BYTES = 5 * 1000000;

function UploadWhitelistForm(props: UploadWhitelistTableFormProps) {
  const { closeParentModal, lotId } = props;
  const [file, setFile] = useState<File | null>(null);
  const [inProgress, setInProgress] = useState(false);
  const [error, setError] = useState('');
  const isMounted = useIsMounted();
  const { uploadWhitelistCsv, exportWhitelistSampleFile } =
    useLotWhitelistsService();

  const { openGlobalSuccessModal } = useGlobalSuccessModal({
    closeParentModal,
    message: 'File was successfully uploaded!',
  });
  const { openGlobalFailureModal, setMessage } = useGlobalFailureModal({});

  const onFileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFile(event.target.files ? event.target.files[0] : null);
    },
    []
  );

  const onUpload = useCallback(async () => {
    try {
      if (!file) return;

      if (isMounted()) {
        setInProgress(true);
      }

      await uploadWhitelistCsv({ file: file, lotId: lotId });

      if (isMounted()) {
        openGlobalSuccessModal();
      }
    } catch (error: any) {
      if (isMounted()) {
        setInProgress(false);
        if (error.file) {
          setError(error.file);
          return;
        }
        setMessage({
          code: 'Undefined',
          message:
            'Sorry for the inconvenience! Something went wrong, please try again.',
        });
        openGlobalFailureModal();
      }
    }
  }, [
    file,
    isMounted,
    uploadWhitelistCsv,
    openGlobalSuccessModal,
    setMessage,
    openGlobalFailureModal,
    lotId,
  ]);

  const isFileTooLarge = useMemo(() => {
    if (!file) {
      return false;
    }

    if (file.size > MAX_SIZE_BYTES) {
      return true;
    }

    return false;
  }, [file]);

  const onDownloadSampleFile = useCallback(async () => {
    try {
      await exportWhitelistSampleFile();
    } catch (error) {
      if (isMounted()) {
        throw error;
      }
    }
  }, [exportWhitelistSampleFile, isMounted]);

  return (
    <>
      <Title addBottomMargin>Import Lot Whitelists</Title>
      <Description>
        Import an CSV file containing the license plate you wish to allow on the
        parking lot.
      </Description>

      <SampleLink onClick={onDownloadSampleFile}>
        Download Sample CSV File
      </SampleLink>

      <DetailsColumn>
        <CustomFileLabel htmlFor='csv-upload'>Choose File</CustomFileLabel>
        <HiddenInput
          id='csv-upload'
          type='file'
          onChange={onFileChange}
          accept='.csv'
          data-testid='hidden-input'
        />
        {file && (
          <Description style={{ marginTop: 10 }}>
            Selected file: <strong>{file.name}</strong>
          </Description>
        )}
        {isFileTooLarge && (
          <Description color={Color.ERROR} errorMessage>
            *File is too large. Maximum size is 5 MB.
          </Description>
        )}
        {error && (
          <Description color={Color.ERROR} errorMessage>
            *{error}
          </Description>
        )}
      </DetailsColumn>

      <Control>
        <Button
          onClick={closeParentModal}
          size={ButtonSize.LARGE}
          type='button'
          disabled={inProgress}
        >
          Cancel
        </Button>
        <Button
          isLoading={inProgress}
          onClick={onUpload}
          size={ButtonSize.LARGE}
          type='submit'
          primary
        >
          Upload
        </Button>
      </Control>
    </>
  );
}

export default UploadWhitelistForm;

const SampleLink = styled.a`
  font-size: 0.9em;
  color: #007bff;
  text-decoration: none;
  display: inline-block;
  margin-bottom: 24px;
  cursor: pointer;
`;

const HiddenInput = styled.input`
  display: none;
`;

const CustomFileLabel = styled.label`
  display: inline-block;
  padding: 10px 16px;
  font-size: 14px;
  font-weight: 500;
  color: ${Color.TEXT_DARKER};
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background-color: #e0e0e0;
  }
`;
