import { useFormikContext } from 'formik';
import React from 'react';
import TerminalStatus from 'src/lots/constants/TerminalStatus';
import type Terminal from 'src/lots/models/Terminal';
import type TerminalUpdateFormValues from 'src/lots/models/TerminalUpdateFormValues';
import DetailsColumn from 'src/products/components/DetailsColumn';
import DetailsData from 'src/products/components/DetailsData';
import DetailsRow from 'src/products/components/DetailsRow';
import DetailsStatus from 'src/products/components/DetailsStatus';
import Button from 'src/shared/components/Button';
import Control from 'src/shared/components/Control';
import Line from 'src/shared/components/Line';
import { useGlobalCancelModal } from 'src/shared/components/Modals/GlobalCancelModal';
import Spinner from 'src/shared/components/Spinner';
import TextField from 'src/shared/components/TextField';
import ButtonSize from 'src/shared/constants/ButtonSize';

interface TerminalUpdateFormProps {
  details?: Terminal;
  isLoading?: boolean;
  closeParentModal: () => void;
}

export default function TerminalUpdateForm(props: TerminalUpdateFormProps) {
  const { details, isLoading, closeParentModal } = props;
  const { isSubmitting, dirty } = useFormikContext<TerminalUpdateFormValues>();

  const { openGlobalCancelModal } = useGlobalCancelModal({
    closeParentModal,
  });

  return (
    <>
      {isLoading && <Spinner primary />}
      {details && (
        <DetailsRow>
          <DetailsColumn>
            <TextField
              label='Terminal name'
              placeholder='Terminal name'
              type='text'
              name='name'
              short
              stacked
            />
            <Line />

            <DetailsStatus
              data-testid='detailsStatus'
              offline={details.status === TerminalStatus.OFFLINE}
              addVerticalMargin
            >
              {details.status}
            </DetailsStatus>

            <Line />

            <DetailsData label='ID' value={details.deviceId || 'N/A'} />
            <DetailsData
              label='IP Address'
              value={details.ipAddress || 'N/A'}
            />
            <DetailsData
              label='Firmware version'
              value={details.version || 'N/A'}
            />
          </DetailsColumn>
        </DetailsRow>
      )}
      <Line />
      <Control>
        <Button
          onClick={dirty ? openGlobalCancelModal : closeParentModal}
          size={ButtonSize.LARGE}
          type='button'
          disabled={isSubmitting}
        >
          Cancel
        </Button>
        <Button
          isLoading={isSubmitting}
          size={ButtonSize.LARGE}
          type='submit'
          primary
          disabled={isSubmitting}
        >
          Save
        </Button>
      </Control>
    </>
  );
}
