import React, { useCallback, useState } from 'react';
import type Terminal from 'src/lots/models/Terminal';
import Card from 'src/shared/components/Card';
import { useGlobalConfirmationModal } from 'src/shared/components/Modals/GlobalConfirmationModal';
import { useGlobalFailureModal } from 'src/shared/components/Modals/GlobalFailureModal';
import GlobalModal from 'src/shared/components/Modals/GlobalModal';
import { useGlobalSuccessModal } from 'src/shared/components/Modals/GlobalSuccessModal';
import StyledNoData from 'src/shared/components/StyledNoData';
import Table from 'src/shared/components/Table';
import { useGlobalModal } from 'src/shared/hooks/useGlobalModal';
import useIsMounted from 'src/shared/hooks/useIsMounted';
import TerminalDetailsModal from '../TerminalDetailsModal/TerminalDetailsModal';
import TerminalsInternalTableRow from '../TerminalsInternalTableRow/TerminalsInternalTableRow';

export interface TerminalsInternalTableProps {
  terminals: Terminal[];
  isLoading: boolean;
  onRemoveTerminal: (id: number) => Promise<void>;
  onEditTerminal: (name: string, id: number) => void;
}

export default function TerminalsInternalTable(
  data: TerminalsInternalTableProps
) {
  const { terminals, isLoading, onRemoveTerminal, onEditTerminal } = data;
  const isMounted = useIsMounted();
  const [successMessage, setSuccessMessage] = useState('');

  const [activeTerminal, setActiveTerminal] = useState<Terminal | undefined>();
  const [activeRemoveId, setActiveRemoveId] = useState<number | undefined>();
  const { openGlobalSuccessModal } = useGlobalSuccessModal({
    message: successMessage,
  });
  const { openGlobalFailureModal, setMessage } = useGlobalFailureModal({});

  const onRemoveTerminalClick = useCallback(async () => {
    try {
      if (!activeRemoveId) return;
      await onRemoveTerminal(activeRemoveId);
      if (isMounted()) {
        setSuccessMessage('Terminal removed successfully!');
        openGlobalSuccessModal();
      }
    } catch (error: any) {
      if (isMounted()) {
        setMessage(error);
        openGlobalFailureModal();
      }
    }
  }, [
    activeRemoveId,
    onRemoveTerminal,
    openGlobalSuccessModal,
    isMounted,
    setMessage,
    openGlobalFailureModal,
  ]);

  const { openGlobalConfirmationModal } = useGlobalConfirmationModal({
    action: onRemoveTerminalClick,
    message: 'Are you sure you want to remove this terminal?',
  });

  const removeItem = useCallback(
    (id?: number) => {
      if (!id) {
        return;
      }
      setActiveRemoveId(id);
      openGlobalConfirmationModal();
    },
    [openGlobalConfirmationModal]
  );

  const [openTerminalDetailsModal, closeTerminalDetailsModal] = useGlobalModal(
    () => (
      <GlobalModal isOpen>
        <TerminalDetailsModal
          closeParentModal={closeTerminalDetailsModal}
          terminal={activeTerminal}
          onTerminalNameChange={onEditTerminal}
        />
      </GlobalModal>
    )
  );

  return (
    <Card>
      {data.terminals.length < 1 ? (
        <StyledNoData>No terminals</StyledNoData>
      ) : (
        <Table isLoading={isLoading}>
          <Table.Head>
            <Table.Row>
              <Table.Header>#ID </Table.Header>
              <Table.Header>DEVICE ID</Table.Header>
              <Table.Header>NAME</Table.Header>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {terminals.map((data) => {
              const { id, name, deviceId } = data;
              const openModal = () => {
                setActiveTerminal(data);
                openTerminalDetailsModal();
              };
              return (
                <TerminalsInternalTableRow
                  key={id}
                  id={id}
                  deviceId={deviceId}
                  name={name}
                  onRemoveItem={removeItem}
                  onOpenModal={openModal}
                />
              );
            })}
          </Table.Body>
        </Table>
      )}
    </Card>
  );
}
