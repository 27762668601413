import { useField } from 'formik';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled, { css } from 'styled-components';
import { isNotArray } from '../../../shared/utils/checks';
import cutOffAt from '../../../shared/utils/cutOffAt';
import invariant from '../../../shared/utils/invariant';
import Color from '../../constants/Color';
import SearchSelectIcon from '../../constants/SearchSelectIcon';
import SelectAllFilterValue from '../../constants/SelectAllFilterValue';
import type Option from '../../models/Option';
import Dropdown from '../Dropdown';
import Error from '../Error';
import Label from '../Label';
import Icon from './Icon';
import Options from './Options';

interface SelectFieldProps {
  name: string;
  label: string | JSX.Element;
  placeholder: string;
  stacked?: boolean;
  options: Option[];
  onSearch?: (value: string) => void;
  search?: string;
  short?: boolean;
  multiple?: boolean;
  selectValue?: (value: Option | undefined) => void;
  hideLabel?: boolean;
  readonly?: boolean;
  disabled?: boolean;
  small?: boolean;
  customMargin?: boolean;
  tariffView?: boolean;
  imageStorageView?: boolean;
  rightAlign?: boolean;
  customWidth?: string;
}

export default function SelectField(props: SelectFieldProps) {
  const {
    name,
    label,
    placeholder,
    stacked,
    options,
    onSearch,
    search = '',
    short,
    multiple,
    selectValue,
    hideLabel,
    readonly,
    disabled,
    small,
    customMargin,
    tariffView,
    imageStorageView,
    rightAlign,
    customWidth,
  } = props;
  const [field, meta, helpers] = useField({ name });
  const wrapperRef = useRef<React.ElementRef<'div'>>(null);
  const inputRef = useRef<React.ElementRef<'input'>>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { value } = field;

  const readonlyValue = useMemo(() => {
    if (!value) {
      return 'N/A';
    }

    return value.label;
  }, [value]);

  const maxLength = useMemo(() => {
    if (!short) {
      return 48;
    }

    return 32;
  }, [short]);

  invariant(
    !multiple || !isNotArray(value),
    'If mulltiple flag is set to true value has to be array'
  );

  const { error, touched } = meta;
  const shouldShowError = error && touched;
  const { setValue, setTouched } = helpers;

  const icon = useMemo(() => {
    if (!isDropdownOpen || !onSearch) {
      return SearchSelectIcon.ARROW_DOWN;
    }

    return SearchSelectIcon.SEARCH;
  }, [isDropdownOpen, onSearch]);

  const createLabel = useCallback((acc: string, { label }: Option) => {
    if (!acc) {
      return label;
    }

    return `${acc}, ${label}`;
  }, []);

  const activePlaceholder = useMemo(() => {
    if (isDropdownOpen && multiple && value.length > 0) {
      return value.reduce(createLabel, '');
    }

    if (isDropdownOpen && !multiple && value != null) {
      return value.label;
    }

    return placeholder;
  }, [value, placeholder, isDropdownOpen, multiple, createLabel]);

  const activeValue = useMemo(() => {
    if (!isDropdownOpen && search.length > 0) {
      return search;
    }

    if (isDropdownOpen && onSearch) {
      return search;
    }

    if (!!value && !multiple) {
      return value.label;
    }

    if (!!value && multiple) {
      return value.reduce(createLabel, '');
    }

    return '';
  }, [isDropdownOpen, value, search, multiple, createLabel, onSearch]);

  const [shouldSetTouched, setShouldSetTouched] = useState(false);

  const onSelect = useCallback(
    (option: Option) => {
      if (onSearch) onSearch('');

      const isSelectedOption = (entry: Option) => entry.key === option.key;

      const isNotSelectedOption = (entry: Option) => entry.key !== option.key;

      if (multiple && value.some(isSelectedOption)) {
        return setValue(value.filter(isNotSelectedOption));
      }

      if (multiple && !value.some(isSelectedOption)) {
        return setValue([...value, option]);
      }

      if (!value || option.key !== value.key) {
        if (selectValue !== undefined)
          option.key === SelectAllFilterValue
            ? selectValue(undefined)
            : selectValue(option);
        return setValue(option);
      }

      if (selectValue !== undefined) selectValue(undefined);

      return setValue(null);
    },
    [setValue, value, multiple, selectValue, onSearch]
  );

  const open = useCallback(() => {
    setIsDropdownOpen(true);

    if (!shouldSetTouched) {
      setShouldSetTouched(true);
    }
  }, [shouldSetTouched]);

  const close = useCallback(() => {
    setIsDropdownOpen(false);
    setShouldSetTouched(false);
  }, []);

  useEffect(() => {
    const listener = (event: MouseEvent | FocusEvent) => {
      if (
        !wrapperRef?.current?.contains(event.target as Node) &&
        shouldSetTouched
      ) {
        close();
        setTouched(true);
        if (search.length > 0) {
          setValue(null);
        }
      }
    };

    document.addEventListener('click', listener, { capture: true });
    document.addEventListener('focusin', listener, { capture: true });

    return () => {
      document.removeEventListener('click', listener, { capture: true });
      document.removeEventListener('focusin', listener, { capture: true });
    };
  }, [close, shouldSetTouched, setTouched, search, setValue]);

  const onInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { target } = event;
      const { value } = target;

      if (isDropdownOpen && onSearch) {
        onSearch(value);
      }
    },
    [isDropdownOpen, onSearch]
  );

  const focusInput = useCallback(() => {
    inputRef?.current?.focus();
  }, []);

  return (
    <StyledWrapper
      stacked={stacked}
      ref={wrapperRef}
      short={short}
      small={small}
      hideLabel={hideLabel}
      customMargin={customMargin}
      tariffView={tariffView}
      imageStorageView={imageStorageView}
      customWidth={customWidth}
    >
      <Label
        stacked={stacked}
        onClick={focusInput}
        hideLabel={hideLabel}
        small={small}
        tariffView={tariffView}
      >
        {label}
      </Label>

      <StyledColumn short={short} small={small} tariffView={tariffView}>
        {readonly && (
          <StyledReadonlyData rightAlign={rightAlign}>
            {readonlyValue}
          </StyledReadonlyData>
        )}
        {!readonly && (
          <StyledContainer disabled={disabled} tariffView={tariffView}>
            <StyledInput
              data-testid='dropdown'
              ref={inputRef}
              type='text'
              onChange={onInputChange}
              onFocus={open}
              value={cutOffAt(activeValue, maxLength)}
              placeholder={cutOffAt(activePlaceholder, maxLength)}
              readOnly={!onSearch}
              small={small}
              disabled={disabled}
            />
            <Icon icon={icon} onClick={focusInput} tariffView={tariffView} />
          </StyledContainer>
        )}
        {shouldShowError && <Error>*{error}</Error>}
        {isDropdownOpen && (
          <Dropdown close={close}>
            <Options
              value={value}
              onSelect={onSelect}
              multiple={multiple}
              options={options}
            />
          </Dropdown>
        )}
      </StyledColumn>
    </StyledWrapper>
  );
}

const StyledReadonlyData = styled.p<{ rightAlign?: boolean }>`
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  height: 36px;
  color: ${Color.TEXT_DARKER};

  ${(props) => {
    const { rightAlign } = props;

    if (rightAlign) {
      return css`
        justify-content: flex-end;
      `;
    }
    return css``;
  }}
`;

const StyledInput = styled.input<{ small?: boolean }>`
  height: 36px;
  border: 1px solid ${Color.BORDER_LIGHT};
  border-radius: 5px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  padding: 0px 14px;
  color: ${Color.TEXT_DARKER};
  background-color: ${Color.BACKGROUND_LIGHTEST};
  width: 100%;

  :focus {
    outline: none;
  }

  ::placeholder {
    color: ${Color.TEXT_LIGHT};
  }

  :disabled {
    background-color: ${Color.BACKGROUND_LIGTH};
  }

  ${(props) => {
    const { small } = props;

    if (small) {
      return css`
        font-size: 12px;
      `;
    }
    return css``;
  }}
`;

const StyledColumn = styled.div<{
  short?: boolean;
  small?: boolean;
  tariffView?: boolean;
}>`
  display: flex;
  flex-direction: column;
  position: relative;

  ${(props) => {
    const { short } = props;

    if (!short) {
      return css`
        width: 100%;
      `;
    }

    return css`
      width: 300px;
      @media (max-width: 500px) {
        width: 250px;
      }
    `;
  }}

  ${(props) => {
    const { small, tariffView } = props;

    if (small) {
      return css`
        width: 150px;
      `;
    }

    if (tariffView) {
      return css`
        width: 100px;
        @media (max-width: 650px) {
          width: 100%;
        }
      `;
    }

    return css``;
  }}
`;

const StyledWrapper = styled.div<{
  stacked?: boolean;
  short?: boolean;
  small?: boolean;
  hideLabel?: boolean;
  customMargin?: boolean;
  tariffView?: boolean;
  imageStorageView?: boolean;
  customWidth?: string;
}>`
  display: flex;
  align-items: flex-start;
  margin: 30px 0px 0px 0px;

  @media (max-width: 999px) {
    width: 100%;
  }

  @media (max-width: 500px) {
    flex-direction: column;
  }

  ${(props) => {
    const { tariffView } = props;

    if (tariffView) {
      return css`
        width: auto;
        @media (max-width: 999px) {
          width: auto;
        }

        @media (max-width: 650px) {
          flex-direction: column;
        }
      `;
    }

    return css`
      width: 100%;
    `;
  }}

  ${(props) => {
    const { hideLabel } = props;

    if (hideLabel) {
      return css`
        margin: 0;
      `;
    }
    return css``;
  }}

  ${(props) => {
    const { customMargin } = props;

    if (customMargin) {
      return css`
        margin: 15px 0px 0px 0px;
      `;
    }
    return css``;
  }}

    ${(props) => {
    const { stacked } = props;

    if (!stacked) {
      return css`
        flex-direction: row;
      `;
    }

    return css`
      flex-direction: column;
    `;
  }}
  
  ${(props) => {
    const { imageStorageView } = props;

    if (imageStorageView) {
      return css`
        width: auto;
        margin-right: 10px;

        @media (max-width: 999px) {
          width: auto;
        }

        @media (max-width: 500px) {
          margin-bottom: 10px;
        }
      `;
    }
    return css``;
  }}

    ${(props) => {
    const { customWidth } = props;

    if (customWidth) {
      return css`
        width: ${customWidth};

        :last-child {
          margin-bottom: 0;
        }
      `;
    }
    return css``;
  }}
`;

const StyledContainer = styled.div<{
  disabled?: boolean;
  tariffView?: boolean;
}>`
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  cursor: pointer;

  ${(props) => {
    const { disabled } = props;

    if (disabled) {
      return css`
        cursor: default;
      `;
    }
    return css``;
  }}

  ${(props) => {
    const { tariffView } = props;

    if (tariffView) {
      return css`
        width: 100px;
      `;
    }

    return css`
      width: 100%;
    `;
  }}
`;
