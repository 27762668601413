import { useCallback } from 'react';
import toLicensePlateAllow from 'src/anpr/mappers/toLicensePlateAllow';
import toLicensePlateAllowRequest from 'src/anpr/mappers/toLicensePlateAllowRequest';
import toLicensePlateRemoveRequest from 'src/anpr/mappers/toLicensePlateRemoveRequest';
import toLotWhitelist from 'src/anpr/mappers/toLotWhitelist';
import toLotWhitelistQuery from 'src/anpr/mappers/toLotWhitelistQuery';
import type LicensePlateAllow from 'src/anpr/models/LotWhitelist/LicensePlateAllow';
import type LicensePlateAllowRequest from 'src/anpr/models/LotWhitelist/LicensePlateAllowRequest';
import type LicensePlateAllowResponse from 'src/anpr/models/LotWhitelist/LicensePlateAllowResponse';
import type LicensePlateByIdRemoveResponse from 'src/anpr/models/LotWhitelist/LicensePlateByIdRemoveResponse';
import type LicensePlateRemoveRequest from 'src/anpr/models/LotWhitelist/LicensePlateRemoveRequest';
import type LicensePlateRemoveResponse from 'src/anpr/models/LotWhitelist/LicensePlateRemoveResponse';
import type LicensePlateRemoveValues from 'src/anpr/models/LotWhitelist/LicensePlateRemoveValues';
import type LotWhitelist from 'src/anpr/models/LotWhitelist/LotWhitelist';
import type LotWhitelistQuery from 'src/anpr/models/LotWhitelist/LotWhitelistQuery';
import type LotWhitelistQueryParams from 'src/anpr/models/LotWhitelist/LotWhitelistQueryParams';
import type LotWhitelistResponse from 'src/anpr/models/LotWhitelist/LotWhitelistResponse';
import ContentType from 'src/shared/constants/ContentType';
import toMeta from 'src/shared/mappers/toMeta';
import type GetAllDetails from 'src/shared/models/GetAllDetails';
import useApiService from '../../shared/services/useApiService';
import toLotWhitelistExportCsvQuery from '../mappers/toLotWhitelistExportCsvQuery';
import type AddLicensePlateFormValues from '../models/LotWhitelist/LicensePlateAllowFormValues';
import type LicensePlateAllowFormValues from '../models/LotWhitelist/LicensePlateAllowFormValues';
import type LotWhitelistCsvExportQuery from '../models/LotWhitelist/LotWhitelistCsvExportQuery';
import type LotWhitelistCsvExportQueryValues from '../models/LotWhitelist/LotWhitelistCsvExportQueryValues';
import type LotWhitelistCsvImportQueryValues from '../models/LotWhitelist/LotWhitelistCsvImportQueryValues';
import type LotWhitelistImportRequest from '../models/LotWhitelist/LotWhitelistImportRequest';
import type LotWhitelistImportResponse from '../models/LotWhitelist/LotWhitelistImportResponse';

const lotWhitelistsURL = '/lot-whitelists';

interface LotWhitelistsService {
  allowLicensePlate: (
    lotId: number,
    values: AddLicensePlateFormValues
  ) => Promise<LicensePlateAllow[]>;
  listLotWhitelist: (
    values: LotWhitelistQueryParams
  ) => Promise<GetAllDetails<LotWhitelist>>;
  removeLicensePlates: (
    values: LicensePlateRemoveValues
  ) => Promise<LicensePlateRemoveResponse[]>;
  removeLicensePlateById: (
    id: number
  ) => Promise<LicensePlateByIdRemoveResponse>;
  exportWhitelistFile: (
    values: LotWhitelistCsvExportQueryValues
  ) => Promise<void>;
  exportWhitelistSampleFile: () => Promise<void>;
  uploadWhitelistCsv: (
    values: LotWhitelistCsvImportQueryValues
  ) => Promise<LotWhitelistImportResponse>;
}

export default function useLotWhitelistsService(): LotWhitelistsService {
  const { post, get, remove, getFile } = useApiService();
  const { post: postFile } = useApiService({
    withAuth: true,
    contentType: ContentType.FORM_DATA,
  });
  const allowLicensePlate = useCallback(
    async (lotId: number, values: LicensePlateAllowFormValues) => {
      const requestBody = toLicensePlateAllowRequest(lotId, values);

      const response = await post<
        LicensePlateAllowRequest,
        LicensePlateAllowResponse[]
      >(`${lotWhitelistsURL}`, requestBody);

      return response.data.map(toLicensePlateAllow);
    },
    [post]
  );

  const listLotWhitelist = useCallback(
    async (values: LotWhitelistQueryParams) => {
      const requestBody = toLotWhitelistQuery(values);

      const response = await get<LotWhitelistResponse[], LotWhitelistQuery>(
        `${lotWhitelistsURL}`,
        requestBody
      );

      const data = response.data.map(toLotWhitelist);
      const meta = toMeta(response.meta);

      return { data, meta };
    },
    [get]
  );

  const removeLicensePlates = useCallback(
    async (ids: LicensePlateRemoveValues) => {
      const request = toLicensePlateRemoveRequest(ids);
      const response = await remove<
        LicensePlateRemoveResponse[],
        LicensePlateRemoveRequest
      >(`${lotWhitelistsURL}/bulk`, request);

      return response.data;
    },
    [remove]
  );

  const removeLicensePlateById = useCallback(
    async (id: number) => {
      const response = await remove<LicensePlateByIdRemoveResponse>(
        `${lotWhitelistsURL}/${id}`
      );

      return response.data;
    },
    [remove]
  );

  const exportWhitelistFile = useCallback(
    async (values: LotWhitelistCsvExportQueryValues) => {
      const requestBody = toLotWhitelistExportCsvQuery(values);
      await getFile<LotWhitelistCsvExportQuery>(
        `${lotWhitelistsURL}/export`,
        requestBody
      );
    },
    [getFile]
  );

  const exportWhitelistSampleFile = useCallback(async () => {
    await getFile<LotWhitelistCsvExportQuery>(
      `${lotWhitelistsURL}/sample/export`
    );
  }, [getFile]);

  const uploadWhitelistCsv = useCallback(
    async (values: LotWhitelistCsvImportQueryValues) => {
      const { lotId, file } = values;
      const response = await postFile<
        LotWhitelistImportRequest,
        LotWhitelistImportResponse
      >(`${lotWhitelistsURL}/upload/lot/${lotId}`, {
        file,
      });
      return response;
    },
    [postFile]
  );

  return {
    allowLicensePlate,
    listLotWhitelist,
    removeLicensePlates,
    removeLicensePlateById,
    exportWhitelistFile,
    exportWhitelistSampleFile,
    uploadWhitelistCsv,
  };
}
