import type LotEntryCsvExportQuery from '../models/LotEntries/LotEntryCsvExportQuery';
import type LotEntryCsvExportQueryValues from '../models/LotEntries/LotEntryCsvExportQueryValues';

export default function toLotEntryExportCsvQuery(
  data: LotEntryCsvExportQueryValues
): LotEntryCsvExportQuery {
  const { filter } = data;

  return {
    filter: filter
      ? `${filter[0].property}:${filter[0].rule}:${filter[0].value}`
      : undefined,
  };
}
